import { Heading } from 'components/common'
import { Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { MemberOfRefsetsType } from '../../interface'

interface MemberOfRefsetsProps {
	memberOfRefsets: MemberOfRefsetsType[]
}

const MemberOfRefsets = ({ memberOfRefsets }: MemberOfRefsetsProps) => {
	const { t } = useTranslation()
	return (
		<Row className='gap-2'>
			<Heading
				variant='h3'
				className='fs-4'
				id='snomed-member-of-refset-heading'
			>
				{t('snomedctPage.memberOfRefsets')}
			</Heading>
			<ul>
				{memberOfRefsets.map((memberOfRefset) => {
					return (
						<li key={memberOfRefset.refsetConceptId}>
							<span>{memberOfRefset.refsetName}</span>{' '}
							<span className='color-grey-shade'>
								({memberOfRefset.refsetConceptId})
							</span>
						</li>
					)
				})}
			</ul>
		</Row>
	)
}

export default MemberOfRefsets
