import type { RequestState } from '@elastic/search-ui'

const routingOptions = {
	readUrl: () => {
		// get the path name and search to ensure we have the path and the searchterm
		const pathname = window.location.pathname
		const search = window.location.search
		return pathname + search
	},
	writeUrl: (url: string) => {
		window.history.pushState(null, '', url)
	},
	stateToUrl: (state: RequestState) => {
		if (state.searchTerm === '') {
			return ''
		}
		return `?q=${state.searchTerm as string}`
	},
	urlToState: (url: string) => {
		const match = url.match(/\?q=(\w+)/)
		if (!match) return { searchTerm: '' }
		return {
			searchTerm: match[1],
		}
	},
}

export default routingOptions
