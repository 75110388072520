const getButtonPadding = (size?: string) => {
	switch (size) {
		case 'xs':
			return '0.2rem 0.6rem'
		case 'sm':
			return '0.3rem 0.8rem'
		case 'md':
			return '0.3rem 0.8rem 0.4rem 1.2rem'
		case 'lg':
			return '0.6rem 1rem'
		case 'xl':
			return '0.6rem 1.2rem'
		case 'xxl':
			return '0.6rem 1.2rem'
	}
}
export default getButtonPadding
