const getTagColor = (value: string) => {
	switch (value) {
		case 'Til utbredelse eller er i bruk':
			return 'green'
		case 'Til høring':
		case 'Til utprøving':
		case 'Til anbefaling':
		case 'Under arbeid':
			return 'yellow'
		case 'Foreldet og erstattet av annet kodeverk':
		case 'Utgått':
			return 'red'
		default:
			return 'yellow'
	}
}

export default getTagColor
