import { Layout } from 'components/layout'
import { Router } from 'components/router'
import { StoreProvider } from 'context'
import { BrowserRouter } from 'react-router-dom'

function App() {
	return (
		<div className='AppContainer'>
			<BrowserRouter>
				<StoreProvider>
					<Layout>
						<Router />
					</Layout>
				</StoreProvider>
			</BrowserRouter>
		</div>
	)
}

export default App
