import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ButtonProps } from './interface'
import { ButtonWrapper } from './style'

const Button = ({
	type = 'button',
	className,
	id,
	size = 'lg',
	icon,
	color,
	variant = 'primary',
	style,
	disabled,
	iconright,
	iconSize,
	iconClassName,
	onClick,
	onKeyDown,
	children,
	textColor,
	link,
	innerRef,
	role,
	ariaLabel,
	name,
	...props
}: ButtonProps) => {
	const handleClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
		if (onClick) {
			onClick(event)
		}
	}
	return (
		<ButtonWrapper
			type={type}
			role={role}
			aria-label={ariaLabel}
			name={name}
			className={`${className} `}
			id={id}
			ref={innerRef}
			$variant={variant}
			$textColor={textColor}
			$size={size}
			color={color}
			onClick={handleClick}
			onKeyDown={onKeyDown}
			style={style}
			disabled={disabled}
			$iconright={iconright}
			{...props}
		>
			{icon && (
				<div className='icon-container'>
					<FontAwesomeIcon
						icon={icon}
						className={` ${iconClassName}`}
						size={iconSize}
					/>
				</div>
			)}
			{children && children}
		</ButtonWrapper>
	)
}

export default Button
