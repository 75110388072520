import {
	faChevronDown,
	faChevronRight,
	faChevronUp,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'components/common'
import { useContext } from 'react'
import { AccordionContext, useAccordionButton } from 'react-bootstrap'
import { CustomAccordionToggleProps } from './interface'

const CustomAccordionToggle = ({
	children,
	eventKey,
	callback,
	iconDownUp,
	iconRight,
	className,
	buttonText,
}: CustomAccordionToggleProps) => {
	const { activeEventKey } = useContext(AccordionContext)

	const decoratedOnClick = useAccordionButton(
		eventKey,
		() => callback && callback(eventKey)
	)

	const isCurrentEventKey = activeEventKey === eventKey

	return (
		<Button
			type='button'
			onClick={decoratedOnClick}
			className={`d-flex align-items-center gap-2 ${className}`}
			size='sm'
		>
			{!iconRight &&
				(isCurrentEventKey ? (
					<FontAwesomeIcon
						icon={iconDownUp ? faChevronUp : faChevronDown}
						size='sm'
						style={{ width: '20px' }}
					/>
				) : (
					<FontAwesomeIcon
						icon={iconDownUp ? faChevronDown : faChevronRight}
						size='sm'
						style={{ width: '20px' }}
					/>
				))}
			<span>{children}</span>
			{iconRight &&
				(isCurrentEventKey ? (
					<>
						{buttonText && <span className='fs-6'>Skjul</span>}
						<FontAwesomeIcon
							icon={iconDownUp ? faChevronUp : faChevronDown}
							size='sm'
							style={{ width: '20px' }}
						/>
					</>
				) : (
					<>
						{buttonText && <span className='fs-6'>Vis</span>}
						<FontAwesomeIcon
							icon={iconDownUp ? faChevronDown : faChevronRight}
							size='sm'
							style={{ width: '20px' }}
						/>
					</>
				))}
		</Button>
	)
}

export default CustomAccordionToggle
