import { Accordion } from 'react-bootstrap'
import { styled } from 'styled-components'

export const AccordionWrapper = styled(Accordion)`
	.card {
		border: none;
		.card-header {
			background-color: ${(props) => props.theme.colours.primary.white};
			border: none;
			padding: 0;
		}

		.card-body {
			border: 1px solid #d8dada;
			border-radius: 5px;
		}
	}
	button {
		font-size: ${(props) => props.theme.fontSizes.bodyText.md};
		span {
			border-bottom: 1px solid ${(props) => props.theme.colours.primary.blue};
		}
		svg {
			color: ${(props) => props.theme.colours.primary.blue};
			width: 16px;
		}
		&:hover {
			text-decoration: none;
			color: ${(props) => props.theme.colours.primary.blue};
		}
		&:active,
		&:focus {
			color: ${(props) => props.theme.colours.primary.blue};
		}
	}
`
