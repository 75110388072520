import { styled } from 'styled-components'

export const Wrapper = styled.div`
	font-size: 16px !important;
	color: ${(props) => props.theme.colours.primary.black};
	background-color: ${(props) => props.theme.colours.primary.white};
	padding: 0;
	em {
		font-weight: bold;
	}
`
