import { autocompleteQuery } from './autocompleteQuery'
import { connector } from './connector'
import { routingOptions } from './routingOptions'
import { searchQuery } from './searchQuery'

export const snomedConfigOptions = {
	apiConnector: connector,
	alwaysSearchOnInitialLoad: true,
	hasA11yNotifications: false,
	autocompleteQuery: autocompleteQuery,
	searchQuery: searchQuery,
	routingOptions: routingOptions,
}
