const getHeadingVariant = (value: number) => {
	switch (value) {
		case 0:
			return 'h2'
		case 1:
			return 'h3'
		case 2:
			return 'h4'
		case 3:
			return 'h5'
		case 4:
			return 'h5'
		default:
			return 'h5'
	}
}

export default getHeadingVariant
