import { withSearch } from '@elastic/react-search-ui'
import { useStoreContext } from 'context/StoreProvider'
import { Col, Nav, Tab } from 'react-bootstrap'
import BodyResults from '../BodyResults'
import {
	chapterString,
	codesystemsEndpointName,
	collectionsString,
	paths,
	searchString,
	historyString,
} from 'utils'
import { AdmResults, ChapterResults } from 'components/search/results'
import { useEffect, useState } from 'react'
import SearchResultLayout from '../SearchResultLayout'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { TabWrapper } from './style'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	faChevronUp,
	faClose,
	faSitemap,
} from '@fortawesome/free-solid-svg-icons'
import { isMobile } from 'react-device-detect'
import { isClinicalCodesystem } from 'components/search/buckets/utils'
import { HistoryList } from 'components/search/history'
import { useTheme } from 'styled-components'

interface TabViewProps {
	totalResults: number
	resultSearchTerm: string
	searchTerm: string
}

const TabView = ({
	totalResults,
	resultSearchTerm,
	searchTerm,
}: TabViewProps) => {
	const { state } = useStoreContext()
	const { codeSystem, code } = state
	const { view } = useParams()
	const navigate = useNavigate()
	const { t } = useTranslation()

	const theme = useTheme()

	const { pathname } = useLocation()

	const isAdm = pathname.startsWith(paths.adm)
	const isNorpat = pathname.startsWith(paths.norpat)

	const [dropdownHierarchy, setDropdownHierarchy] = useState(true)
	const [chaperView, setChaperView] = useState(true)
	let smallScreen = isMobile || window.innerWidth < 992

	const updateWindowDimensions = () => {
		setDropdownHierarchy(window.innerWidth < 992 ? true : false)
		smallScreen = isMobile || window.innerWidth < 992
	}
	window.addEventListener('resize', updateWindowDimensions)

	const isChapter =
		codeSystem === codesystemsEndpointName.adm
			? collectionsString
			: chapterString

	const handleSetActiveTab = (newActiveKey: string) => {
		if (view === newActiveKey) {
			return null
		} else {
			navigate(newActiveKey)
		}
	}

	useEffect(() => {
		// get search from url to check if it matches, if it does we do not navigate to search but show the searchterm that we brought from our previous location in the searchbar
		const search = window.location.search?.match(/\?q=(\w+)/)
		const hasSearchresultsWithMatchOrNoSearch = search
			? search[1] !== searchTerm
			: true
		if (view === undefined) {
			setChaperView(true)
			navigate(isChapter)
		} else if (view === isChapter) {
			setChaperView(true)
		} else {
			setChaperView(false)
		}
		if (
			searchTerm !== resultSearchTerm &&
			hasSearchresultsWithMatchOrNoSearch &&
			view === isChapter
		) {
			navigate(searchString)
			setChaperView(false)
		}
	}, [view, searchTerm])

	const headerHeight = document.getElementById('site-header')?.clientHeight || 0
	const tabNavHeight =
		document.getElementById('codesystem-tab-nav')?.clientHeight || 0
	const searchWrapperHeight =
		document.getElementById('search-wrapper')?.clientHeight || 0

	const calculateHeight = headerHeight + tabNavHeight + searchWrapperHeight

	const historyTabName = isAdm
		? t('adminPage.historyView')
		: t('general.historyView')

	return (
		<>
			<Tab.Container activeKey={view} onSelect={(k) => navigate(k as string)}>
				<TabWrapper
					className='flex-column'
					$topValue={
						isAdm
							? calculateHeight
							: isNorpat
							? calculateHeight + 1
							: calculateHeight + 2
					}
				>
					<Col
						id={`tab-view-${codeSystem}`}
						className='tab-bar d-flex flex-row flex-nowrap overflow-auto justify-content-between'
					>
						<Nav tabIndex={0} className='flex-row flex-nowrap flex-shrink-0'>
							<Nav.Item
								onClick={() => handleSetActiveTab(isChapter)}
								onKeyDown={(e) => {
									if (e.key === 'Enter' || e.key === ' ') {
										handleSetActiveTab(isChapter)
									}
								}}
								aria-label={`${
									(t('general.navigateTo'), t('general.treeView'))
								}`}
							>
								<Nav.Link eventKey={isChapter}>
									{codeSystem === codesystemsEndpointName.adm
										? t('resultPage.collections')
										: t('general.treeView')}
								</Nav.Link>
							</Nav.Item>
							<Nav.Item
								onClick={() => handleSetActiveTab(searchString)}
								onKeyDown={(e) => {
									if (e.key === 'Enter' || e.key === '') {
										handleSetActiveTab(searchString)
									}
								}}
								aria-label={`${
									(t('general.navigateTo'), t('general.searchView'))
								}`}
							>
								<Nav.Link eventKey={searchString}>{`${t('general.searchView')}${
									totalResults > 0 && resultSearchTerm
										? ` (${totalResults})`
										: ''
								}`}</Nav.Link>
							</Nav.Item>
							<Nav.Item
								onClick={() => handleSetActiveTab(historyString)}
								onKeyDown={(e) => {
									if (e.key === 'Enter' || e.key === ' ') {
										handleSetActiveTab(historyString)
									}
								}}
								aria-label={`${(t('general.navigateTo'), historyTabName)}`}
							>
								<Nav.Link eventKey={historyString}>{historyTabName}</Nav.Link>
							</Nav.Item>
						</Nav>
					</Col>
					<Col className='p-0'>
						<Tab.Content>
							{/* Start Mobile hierarchy button */}
							{chaperView && smallScreen && (
								<div
									style={{
										position: 'relative',
										height: '0',
										width: '0',
										float: 'right',
									}}
								>
									<button
										style={{
											height: '3rem',
											width: '3rem',
											borderRadius: '6px',
											position: 'absolute',
											right: '0.125em',
											top: '0.125em',
											border: 'none',
											zIndex: 3,
											backgroundColor: dropdownHierarchy
												? theme.colours.secondary.red
												: theme.colours.secondary.Secondary700,
										}}
										onClick={() => setDropdownHierarchy(!dropdownHierarchy)}
									>
										{dropdownHierarchy ? (
											<FontAwesomeIcon icon={faClose} size='sm' color='white' />
										) : (
											<FontAwesomeIcon
												icon={faSitemap}
												size='sm'
												color='white'
											/>
										)}
									</button>
								</div>
							)}
							{/* End Mobile hierarchy button */}
							<Tab.Pane eventKey={isChapter} tabIndex={0}>
								<SearchResultLayout
									dropdownHierarchy={dropdownHierarchy}
									isClinical={isClinicalCodesystem(codeSystem!)}
									isAdm={codeSystem === codesystemsEndpointName.adm}
									results={
										codeSystem === codesystemsEndpointName.adm ? (
											<AdmResults codeSystemId={code} />
										) : (
											<ChapterResults />
										)
									}
								/>
							</Tab.Pane>
							<Tab.Pane eventKey={searchString} tabIndex={0}>
								<BodyResults />
							</Tab.Pane>
							<Tab.Pane eventKey={historyString} tabIndex={0}>
								<HistoryList />
							</Tab.Pane>
						</Tab.Content>
					</Col>
				</TabWrapper>
			</Tab.Container>
		</>
	)
}

export default withSearch(({ totalResults, resultSearchTerm, searchTerm }) => ({
	totalResults,
	resultSearchTerm,
	searchTerm,
}))(TabView)
