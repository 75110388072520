const sortCodeValues = (
	a: { sortKey: string; value: string },
	b: { value: string; sortKey: string }
) => {
	// sortKeys start at 1 for Kodeverk i standarder, if 0 or undefined, sort by value
	if (a.sortKey === '0' || a.sortKey === undefined) {
		if (!isNaN(parseInt(a.value)) && !isNaN(parseInt(b.value))) {
			return parseInt(a.value) - parseInt(b.value)
		} else {
			return a.value.localeCompare(b.value)
		}
	} else {
		return parseInt(a.sortKey) - parseInt(b.sortKey)
	}
}

export default sortCodeValues
