import { styled } from 'styled-components'

export const Wrapper = styled.div`
	.sui-results-per-page__label {
		font-size: 16px;
		color: ${(props) => props.theme.colours.primary.black};
		padding: 0;
		margin-right: 0.3rem;
	}
	.sui-select__control {
		border: none !important;
		font-size: 16px;
		background-color: ${(props) => props.theme.colours.primary.white};
	}
	.sui-select__value-container {
		padding: 0;
	}
	.sui-select__indicator-separator {
		display: none;
	}
	.sui-select__menu {
		margin-top: 0;
	}
	.sui-select__dropdown-indicator {
		svg {
			color: ${(props) => props.theme.colours.primary.black};
		}
	}
`
