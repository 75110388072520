import {
	ReverseBooleanFacet,
	ClearFilters,
	CustomFacet,
	FilterTitle,
	SortingView,
	BooleanFacet,
	CustomFilter,
	SearchFieldFilter,
	AdmActiveFacet,
} from 'components/common'
import { SidebarFilterWrapper } from 'components/search/buckets/common'
import {
	isClinicalCodesystem,
	isSmallLabCodesystem,
} from 'components/search/buckets/utils'
import { useStoreContext } from 'context/StoreProvider'
import { Col } from 'react-bootstrap'
import { isDesktop } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { codesystemsEndpointName } from 'utils'

interface SidebarFilterProps {
	searchTerm: string
}

const SidebarFilter = ({ searchTerm }: SidebarFilterProps) => {
	const { t } = useTranslation()
	const { state } = useStoreContext()
	const { codeSystem } = state
	const { pathname } = useLocation()

	return (
		<SidebarFilterWrapper
			$isDesktop={isDesktop}
			className={`d-flex flex-column gap-3 pt-3 ${
				isSmallLabCodesystem(pathname) ? 'pt-lg-0' : 'pt-lg-2'
			}`}
		>
			{/* Wrapping all boolean filters, because the parent handles gap between filters */}
			<span className='d-flex flex-column gap-2'>
				<FilterTitle
					hasBorderBottom={true}
					className='d-flex justify-content-between'
				>
					{t('nlkPage.filter.title')}
					<ClearFilters />
				</FilterTitle>
				{/* {searchTerm ? (
					<button
						onClick={(e) => {
							e.preventDefault()
							const element = document.getElementById('search-results')
							if (element) {
								element.scrollIntoView()
								element.focus()
							}
						}}
						className='skip-link'
					>
						{t('accessibility.jumpToResult')}
					</button>
				) : null} */}

				{/* All the codesystems will probably have different filters, 
						may be a good idea to create a switch case if this code is too long */}
				{codeSystem !== codesystemsEndpointName.adm && (
					<CustomFacet
						field='active'
						label={t('search.filters.showInactiveCodes')}
						filterType='any'
						view={ReverseBooleanFacet}
					/>
				)}

				{codeSystem === codesystemsEndpointName.norpat && (
					<>
						<CustomFacet
							field='is_apat'
							label={t('norpatPage.filters.showOnlyApat')}
							filterType='any'
							view={BooleanFacet}
							isFilterable
						/>
						<CustomFacet
							field='chapter_code'
							label={t('norpatPage.filters.chapterFilterTitle')}
							filterType='any'
							view={CustomFilter}
							isFilterable
							sortAlphabetically={true}
						/>
					</>
				)}

				{/* This should be uncommented when the elastic backend is ready */}
				{codeSystem === codesystemsEndpointName.icd10 && (
					<>
						<CustomFacet
							field='reportable_npr'
							label={t('icd10Page.filters.showOnlyNpr')}
							filterType='any'
							view={BooleanFacet}
						/>
						<CustomFacet
							field='star_code'
							label={t('icd10Page.filters.showOnlyStarCodes')}
							filterType='any'
							view={BooleanFacet}
						/>
					</>
				)}
				{codeSystem === codesystemsEndpointName.icd10 ||
				codeSystem === codesystemsEndpointName.nkpk ? (
					<CustomFacet
						field='additional_code'
						label={t('icd10Page.filters.showOnlyAdditionalCodes')}
						filterType='any'
						view={BooleanFacet}
					/>
				) : null}
			</span>

			<Col className='d-flex flex-column' xs='auto'>
				{isClinicalCodesystem(pathname) && (
					<SortingView
						label={t('search.sorting.title')}
						sortOptions={[
							{
								name: t('search.sorting.relevance'),
								value: '',
								direction: '',
							},
							{
								name: t('search.sorting.code'),
								value: 'code',
								direction: 'asc',
							},
							{
								name: t('search.sorting.nameNorwegian'),
								value: 'name_norwegian',
								direction: 'asc',
							},
						]}
					/>
				)}

				{isSmallLabCodesystem(pathname) && (
					<SortingView
						label={t('nlkPage.sort.title')}
						sortOptions={[
							{
								name: t('search.sorting.relevance'),
								value: '',
								direction: '',
							},
							{
								name: t('search.sorting.nameNorwegian'),
								value: 'name_norwegian',
								direction: 'asc',
							},
							{
								name: t('search.sorting.validFrom'),
								value: 'valid_from',
								direction: 'asc',
							},
							{
								name: t('search.sorting.validTo'),
								value: 'valid_to',
								direction: 'asc',
							},
						]}
					/>
				)}

				{codeSystem === codesystemsEndpointName.norpat && (
					<SortingView
						label={t('nlkPage.sort.title')}
						sortOptions={[
							{
								name: t('search.sorting.relevance'),
								value: '',
								direction: '',
							},
							{
								name: t('search.sorting.codeValue'),
								value: 'code',
								direction: 'asc',
							},
							{
								name: t('search.sorting.nameNorwegian'),
								value: 'name_norwegian',
								direction: 'asc',
							},
							{
								name: t('search.sorting.validFrom'),
								value: 'valid_from',
								direction: 'asc',
							},
							{
								name: t('search.sorting.validTo'),
								value: 'valid_to',
								direction: 'asc',
							},
						]}
					/>
				)}

				{codeSystem === codesystemsEndpointName.adm && (
					<SortingView
						label={t('nlkPage.sort.title')}
						sortOptions={[
							{
								name: t('search.sorting.relevance'),
								value: '',
								direction: '',
							},
							{
								name: t('general.id'),
								value: 'compact_code',
								direction: 'asc',
							},
							{
								name: t('general.codesystem'),
								value: 'name_norwegian',
								direction: 'asc',
							},
							{
								name: t('adminPage.results.owner'),
								value: 'owner_name',
								direction: 'asc',
							},
							{
								name: t('search.sorting.category'),
								value: 'text_max_60',
								direction: 'asc',
							},
							{
								name: t('search.sorting.nameAdm'),
								value: 'parent_code',
								direction: 'asc',
							},
						]}
					/>
				)}
			</Col>

			{/* This should be uncommented when the elastic backend is ready */}
			{codeSystem === codesystemsEndpointName.adm && (
				<>
					<SearchFieldFilter
						label={'Søk i koder'}
						searchField='code'
						removedWhenSelected={false}
						initialState={false}
					/>
					<CustomFacet
						field='status_text'
						label={'Vis inaktive kodeverk'}
						view={AdmActiveFacet}
						filterType='any'
						isFilterable
					/>
					<CustomFacet
						field='owner_name'
						label={t('adminPage.results.owner')}
						view={CustomFilter}
						filterType='any'
						isFilterable
						show={20}
						showDisabledOptions={false}
					/>
				</>
			)}

			{codeSystem === codesystemsEndpointName.icpc2 && (
				<>
					<CustomFacet
						field='code_system'
						label={t('general.codesystem')}
						view={CustomFilter}
						filterType='any'
						isFilterable
						show={20}
					/>
				</>
			)}

			{codeSystem === codesystemsEndpointName.phbu && (
				<>
					<CustomFacet
						field='chapter_code'
						label={t('search.filters.axis')}
						view={CustomFilter}
						filterType='any'
						isFilterable
						show={20}
						sortAlphabetically={true}
					/>
				</>
			)}
		</SidebarFilterWrapper>
	)
}

export default SidebarFilter
