import { withSearch } from '@elastic/react-search-ui'
import { Button } from 'components/common'
import { useTranslation } from 'react-i18next'

interface ClearFiltersProps {
	filters: string[]
	clearFilters: () => void
}

const ClearFilters = ({ filters, clearFilters }: ClearFiltersProps) => {
	const { t } = useTranslation()
	return (
		<Button
			disabled={filters.length < 1}
			size='sm'
			onClick={() => clearFilters()}
			onKeyDown={(event) => {
				if (event.key === 'Enter' || event.key === ' ') {
					clearFilters()
				}
			}}
			className='p-0'
		>
			{t('search.clearFilters')}
		</Button>
	)
}

export default withSearch(({ filters, clearFilters }) => ({
	filters,
	clearFilters,
}))(ClearFilters)
