import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Form } from 'react-bootstrap'
import { maxInputLength } from 'utils'
import type { InputViewProps } from '@elastic/react-search-ui-views'
import { useTranslation } from 'react-i18next'
import { useStoreContext } from 'context/StoreProvider'

const InputView = ({ getInputProps, getAutocomplete }: InputViewProps) => {
	const { t } = useTranslation()

	const { state } = useStoreContext()

	const { inputRef, codeSystem } = state

	const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
		const searchTerm = inputRef?.current?.value
		/* Prevent user from using space as first letter */
		if (event.key === ' ' && searchTerm === '') {
			event.preventDefault()
		} else if (event.key === ' ' && searchTerm?.charAt(0) === ' ') {
			event.preventDefault()
		}
	}

	const inputProps = {
		placeholder: t('frontpage.search.placeholder'),
		'aria-label': t('frontpage.search.placeholder'),
	}

	return (
		<>
			<FontAwesomeIcon icon={faMagnifyingGlass} className='search-icon' />
			<Form.Control
				id={`${codeSystem}-search-input`}
				{...getInputProps(inputProps)}
				ref={inputRef}
				maxLength={maxInputLength}
				onKeyDown={handleKeyPress}
				role='search'
				aria-labelledby={`${codeSystem}-search-label`}
			/>

			{getAutocomplete()}
		</>
	)
}

export default InputView
