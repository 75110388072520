import { createRoot } from 'react-dom/client'
import 'bootstrap/dist/css/bootstrap.min.css'
import App from './App'
import './i18n'
import { theme } from './components/style/theme'
import { GlobalStyle } from './components/style/global'
import { ThemeProvider } from 'styled-components'
import { StrictMode } from 'react'

const root = createRoot(document.getElementById('root') as HTMLElement)
root.render(
	<StrictMode>
		<ThemeProvider theme={theme}>
			<GlobalStyle />
			<App />
		</ThemeProvider>
	</StrictMode>
)
