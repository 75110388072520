import type { TagProps } from './interface'
import { TagWrapper } from './style'
import parse from 'html-react-parser'

const Tag = ({ text, color }: TagProps) => {
	return (
		<span>
			<TagWrapper color={color} className='text-nowrap'>
				{parse(text)}
			</TagWrapper>
		</span>
	)
}

export default Tag
