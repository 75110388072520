import { Container } from 'react-bootstrap'
import type { ContainerProps } from 'react-bootstrap'

interface WrapperProps {
	children: React.ReactNode
	className?: string
}

const Wrapper = ({
	children,
	className,
	...props
}: WrapperProps & ContainerProps) => {
	return (
		<Container className={className} fluid={true} {...props}>
			{children}
		</Container>
	)
}

export default Wrapper
