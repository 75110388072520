import { Heading, Link } from 'components/common'
import { Row, Table } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { getCodeSystemString } from 'utils'
import { MapsToType } from '../../interface'
import { useStoreContext } from 'context/StoreProvider'

interface MapsToProps {
	mapsTo: MapsToType[]
}

const MapsToTable = ({ mapsTo }: MapsToProps) => {
	const { t } = useTranslation()
	const { actions } = useStoreContext()
	const { updateCodeValue } = actions
	return (
		<Row className='gap-2'>
			<Heading variant='h3' className='fs-4' id='snomed-maps-to-heading'>
				{t('snomedctPage.mapsTo.title')}
			</Heading>
			<Table className='p-0' responsive striped hover>
				<thead>
					<tr>
						<th>{t('snomedctPage.mapsTo.system')}</th>
						<th>{t('snomedctPage.mapsTo.targetName')}</th>
						<th>{t('snomedctPage.mapsTo.targetId')}</th>
					</tr>
				</thead>
				<tbody>
					{mapsTo.map((mapTo) => (
						<tr key={mapTo.targetId}>
							<td>{mapTo.system}</td>
							<td>{mapTo.targetName}</td>
							<td>
								{mapTo.system.includes('ICD-10') ||
								mapTo.system.includes('ICPC-2') ? (
									<Link
										textElement={
											<>
												<span className='link-style'>{mapTo.targetId}</span>
											</>
										}
										href={`/${getCodeSystemString(mapTo.system)}/chapter/${
											mapTo.targetId
										}`}
										onClick={() => updateCodeValue(mapTo.targetId)}
									/>
								) : (
									mapTo.targetId
								)}
							</td>
						</tr>
					))}
				</tbody>
			</Table>
		</Row>
	)
}

export default MapsToTable
