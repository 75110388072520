const getButtonBorderSize = (borderSize?: string) => {
	switch (borderSize) {
		case '1':
			return '1px'
		case '2':
			return '2px'
		case '3':
			return '3px'
		case '4':
			return '4px'
	}
}

export default getButtonBorderSize
