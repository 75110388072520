import { withSearch } from '@elastic/react-search-ui'
import { Col, Row } from 'react-bootstrap'
import { paths } from 'utils'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { SearchOverlayProps } from './interface'
import {
	MoreResultsButton,
	SearchOverlayWrapper,
	SearchResultsWrapper,
} from './style'
import DefaultResultView from './DefaultResultView'
import { ErrorMessage, Loading } from 'components/common'
import type { SearchResult } from '@elastic/search-ui/lib/esm/types'

const SearchOverlay = ({
	searchTerm,
	totalResults,
	wasSearched,
	isLoading,
	results,
	facets,
}: SearchOverlayProps) => {
	const { pathname } = useLocation()
	const { t } = useTranslation()
	const navigate = useNavigate()

	const windowHeight = window.innerHeight > 800

	const hasSearchTerm = searchTerm.length > 1

	const hasResults = hasSearchTerm && !isLoading && totalResults > 0

	const noResults = wasSearched && totalResults === 0 && searchTerm.length > 0

	const beforeSearch = searchTerm.length < 1

	const isMain = pathname === paths.main

	const handleOnClick = () => {
		navigate(`${paths.result}/?q=${searchTerm}`)
	}

	const totalFilterResults = facets.code_system[0].data.length

	return (
		<div
			className={`position-relative ${
				isMain ? 'd-flex justify-content-center' : ''
			}`}
		>
			<SearchOverlayWrapper $hasEnoughHeight={windowHeight}>
				{isLoading ? (
					<Loading />
				) : (
					<>
						<Row
							className={`flex-sm-row	align-items-start align-items-sm-center justify-content-between align-content-center px-3`}
						>
							{(beforeSearch || searchTerm.length <= 1) && (
								<Col className='p-0 pt-3 pb-3 d-flex flex-column gap-3'>
									{t('search.typeToSearch')}
								</Col>
							)}
						</Row>

						{/* Vises når feltet er i fokus, men tomt */}

						{/* Vises dersom det ikke finnes resultater for gitt searchTerm */}
						{noResults && (
							<>
								<ErrorMessage
									noResults
									searchTerm={searchTerm}
									className='py-2'
								/>
								<MoreResultsButton onClick={handleOnClick}>
									{t('search.advancedSearch', {
										term: searchTerm,
									})}
								</MoreResultsButton>
							</>
						)}
						{/* Loading-indikator før treff blir synlig, deretter vis resultater */}
						{hasResults && (
							<>
								<SearchResultsWrapper variant='flush'>
									{results.map((result: SearchResult, i) => {
										return (
											<DefaultResultView
												key={i}
												result={result}
												searchTerm={searchTerm}
											/>
										)
									})}
								</SearchResultsWrapper>
								{/* Forsiden har ikke lenger paginasjon, men man kan gå til søkeresultater på en egen side */}
								{hasSearchTerm && isMain && (
									<MoreResultsButton role='button' onClick={handleOnClick}>
										<div className='fw-bold'>
											{t('search.showingResultsAndFilters', {
												term: searchTerm,
												count: totalResults,
												filterCount: totalFilterResults,
											})}
										</div>
										{t('search.advancedSearch')}
									</MoreResultsButton>
								)}
							</>
						)}
					</>
				)}
			</SearchOverlayWrapper>
		</div>
	)
}

export default withSearch(
	({
		searchTerm,
		totalPages,
		current,
		setCurrent,
		totalResults,
		removeFilter,
		wasSearched,
		setFilter,
		isLoading,
		addFilter,
		results,
		filters,
		facets,
	}) => ({
		searchTerm,
		totalPages,
		current,
		setCurrent,
		removeFilter,
		totalResults,
		wasSearched,
		setFilter,
		isLoading,
		addFilter,
		results,
		filters,
		facets,
	})
)(SearchOverlay)
