import { withSearch } from '@elastic/react-search-ui'
import { Loading } from 'components/common'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import ResultView from '../ResultView'
import { useStoreContext } from 'context/StoreProvider'

interface BodyResultsProps {
	searchTerm: string
	totalResults: number
	resultSearchTerm: string
	isFetching: boolean
	isLoading: boolean
}

const BodyResults = ({
	searchTerm,
	resultSearchTerm,
	isLoading,
}: BodyResultsProps) => {
	const navigate = useNavigate()

	const { state, actions } = useStoreContext()

	const { code, inputRef } = state

	const { updateCodeValue } = actions

	/* This checks if the searchTerm changes to something different than the first searchTerm, 
  so that the content updates with the result if the user searches for something else  */
	useEffect(() => {
		if (searchTerm !== resultSearchTerm && code) {
			updateCodeValue('')
		}
	}, [searchTerm, resultSearchTerm, code, navigate, updateCodeValue])

	/* This makes sure the input gets refocused after the searchTerm state update */
	useEffect(() => {
		if (inputRef?.current) {
			inputRef.current.focus()
		}
	}, [searchTerm])
	return (
		<>
			{code ? (
				<></>
			) : (
				<>
					{resultSearchTerm !== '' && (
						<>{isLoading ? <Loading /> : <ResultView />}</>
					)}
				</>
			)}
		</>
	)
}

export default withSearch(
	({ searchTerm, totalResults, resultSearchTerm, isLoading }) => ({
		searchTerm,
		totalResults,
		resultSearchTerm,
		isLoading,
	})
)(BodyResults)
