import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useContext } from 'react'
import { AccordionContext, useAccordionButton } from 'react-bootstrap'
import { ButtonWrapper } from './style'
import { CustomAccordionToggleProps } from './interface'

const CustomAccordionToggle = ({
	eventKey,
	callback,
	className,
}: CustomAccordionToggleProps) => {
	const { activeEventKey } = useContext(AccordionContext)

	const decoratedOnClick = useAccordionButton(
		eventKey,
		() => callback && callback(eventKey)
	)

	const isAccordionOpen = activeEventKey?.includes(eventKey)

	return (
		<ButtonWrapper
			type='button'
			role='button'
			onClick={decoratedOnClick}
			className={`p-0 ${className}`}
			style={{ maxWidth: '10px' }}
		>
			{isAccordionOpen ? (
				<FontAwesomeIcon icon={faChevronUp} size='sm' />
			) : (
				<FontAwesomeIcon icon={faChevronDown} size='sm' />
			)}
		</ButtonWrapper>
	)
}

export default CustomAccordionToggle
