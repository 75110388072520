interface CodeSystem {
	[key: string]: string
}

const codesystemsEndpointName: CodeSystem = {
	icd10: 'icd10',
	icpc2: 'icpc2',
	icpc2b: 'icpc2b',
	nkpk: 'nkpk',
	phbu: 'phbu',
	norpat: 'norpat',
	apat: 'apat',
	nlk: 'nlk',
	adm: 'adm',
	provemateriale: 'provemateriale',
	anatomisk: 'anatomisk',
	snomedct: 'snomedct',
	snomed: 'snomed',
	tekstligeresultatverdier: 'tekstligeresultatverdier',
	undersokelsesmetode: 'undersokelsesmetode',
}

export default codesystemsEndpointName
