import { styled } from 'styled-components'

const FilterTitleStyle = styled.div<{ $hasBorderBottom?: boolean }>`
	font-size: 18px;
	font-weight: bold;
	text-transform: none;
	letter-spacing: normal;
	color: ${(props) => props.theme.colours.primary.black};
	border-bottom: ${(props) =>
		props.$hasBorderBottom && `1px solid ${props.theme.colours.primary.black}`};
	padding-bottom: 0.3rem;
`

interface FilterTitleProps {
	children: React.ReactNode
	htmlFor?: string
	as?: string
	ariaLabel?: string
	hasBorderBottom?: boolean
	className?: string
}

const FilterTitle = ({
	children,
	htmlFor,
	className,
	ariaLabel,
	hasBorderBottom,
	as,
	...props
}: FilterTitleProps) => {
	return (
		<FilterTitleStyle
			$hasBorderBottom={hasBorderBottom}
			as={as}
			className={className}
			aria-label={ariaLabel}
			{...props}
		>
			{children}
		</FilterTitleStyle>
	)
}

export default FilterTitle
