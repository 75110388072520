import { ListGroup, Toast, ToastContainer } from 'react-bootstrap'
import { styled } from 'styled-components'

export const Wrapper = styled.div`
	height: 100%;
	.text-box {
		border-left: 2px solid ${(props) => props.theme.colours.primary.blue};
		padding-left: 1rem;
	}

	hr {
		margin: 0.5rem 0;
	}
`

export const CodeValuesItem = styled(ListGroup.Item)`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	.delete-text {
		color: ${(props) => props.theme.colours.secondary.red};
		cursor: pointer;
		display: flex;
		gap: 0.5rem;
		align-items: center;
	}
`

export const StyledToast = styled(Toast)<{ $isGreen: boolean }>`
	background-color: ${(props) =>
		props.$isGreen
			? props.theme.colours.secondary.greenSignal
			: props.theme.colours.secondary.redSignal};
	padding: 0.6rem 1rem;
	text-align: center;
	width: 100%;
	display: flex;
	align-items: center;
	margin-bottom: 0.5rem !important;
`

export const DownloadContainer = styled(ToastContainer)`
	position: sticky !important;
	padding: 0.5rem;
	bottom: 0;
	left: 100%;
	align-self: flex-end;
`

export const ScrollWrapper = styled.article`
	width: 100%;
`
