const autocompleteQuery = {
	suggestions: {
		types: {
			documents: {
				fields: [
					'name_norwegian',
					'code',
					'compact_code',
					'code_system',
					'code_values',
					'inclusion',
				],
			},
		},
		size: 5,
	},
}

export default autocompleteQuery
