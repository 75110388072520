import { Button } from 'components/common'
import { styled } from 'styled-components'

export const ButtonWrapper = styled(Button)`
	&:hover {
		text-decoration: none;
	}
	svg {
		color: ${(props) => props.theme.colours.primary.blue};
	}
`
