import type { FilterType, RequestState } from '@elastic/search-ui'
import { paths } from 'utils'

export const routingOptions = {
	readUrl: () => {
		const pathname = window.location.pathname
		return pathname
	},
	writeUrl: (url: string) => {
		window.history.pushState(null, '', url)
	},
	stateToUrl: (state: RequestState) => {
		const pathname = window.location.pathname
		const codeSystemFilter = state.filters?.find(
			(filter) => filter.field === 'code_system'
		)

		if (state.searchTerm === '') {
			return ''
		}
		const codesystems = codeSystemFilter
			? codeSystemFilter.values.join(',')
			: ''
		/* TODO: Legge til mulighet for filter i URL på forside */
		if (pathname !== paths.main) {
			return `?q=${state.searchTerm as string}`
		}
		return `?q=${state.searchTerm as string}${
			codesystems && pathname === paths.main ? `&codeSystem=${codesystems}` : ''
		}`
	},
	urlToState: (url: string) => {
		const match = url.match(/\?q=(\w+)&codeSystem=(\w+)/)
		if (!match) return { searchTerm: '', filters: [] }
		return {
			searchTerm: match[1],
			filters: [
				{
					field: 'code_system',
					values: [match[2].split(',')],
					type: 'any' as FilterType,
				},
			],
		}
	},
}
