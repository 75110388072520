import { codesystemsEndpointName, paths } from 'utils'
import { type DOMNode, Element, domToReact } from 'html-react-parser'
import { Link } from 'react-router-dom'
import { getCodeSystem } from '../ChapterResults/utils'

/*  
		- Replaces the a-tag with a Link from react-router-dom
		- Replaces the href with the correct path	
*/

const replaceLink = (
	domNode: Element,
	codeSystem: string,
	onClick?: () => void
) => {
	if (
		codeSystem === codesystemsEndpointName.icpc2 &&
		domNode instanceof Element &&
		domNode.attribs.href
	) {
		const removeHashTagandLink = domNode.attribs?.href
			?.replaceAll('#', 'chapter/')
			.replaceAll('/codesystem/ICD-10/concept/', `${paths.icd10}/chapter/`)
		return (
			<Link
				to={`${removeHashTagandLink}`}
				onClick={onClick}
				className='text-decoration-none'
			>
				{domToReact(domNode.children as DOMNode[])}
			</Link>
		)
	} else if (domNode instanceof Element && domNode.attribs?.href) {
		const removeHashTag = domNode.attribs.href.replaceAll('#', '')
		return (
			<Link
				to={`${getCodeSystem(codeSystem)}/chapter/${removeHashTag}`}
				className='text-decoration-none'
			>
				{domToReact(domNode.children as Element[])}
			</Link>
		)
	}
}

export default replaceLink
