import codesystemsEndpointName from '../constants/codesystemsEndpointName'
import paths from '../constants/paths'

const codeSystemMap: Record<string, string> = {
	[paths.icd10]: codesystemsEndpointName.icd10,
	[paths.icpc2]: codesystemsEndpointName.icpc2,
	[paths.nkpk]: codesystemsEndpointName.nkpk,
	[paths.phbu]: codesystemsEndpointName.phbu,
	[paths.anatomisk]: codesystemsEndpointName.anatomisk,
	[paths.norpat]: codesystemsEndpointName.norpat,
	[paths.provemateriale]: codesystemsEndpointName.provemateriale,
	[paths.tekstligeresultatverdier]:
		codesystemsEndpointName.tekstligeresultatverdier,
	[paths.undersokelsesmetode]: codesystemsEndpointName.undersokelsesmetode,
	[paths.adm]: codesystemsEndpointName.adm,
	[paths.nlk]: codesystemsEndpointName.nlk,
	[paths.snomed]: codesystemsEndpointName.snomedct,
}

const getCodeSystemEndpoint = (pathname: string) => {
	for (const path in codeSystemMap) {
		if (pathname.includes(path)) {
			return codeSystemMap[path]
		}
	}

	return
}

export default getCodeSystemEndpoint
