import React from 'react'
import { appendClassName } from 'utils'
import { Wrapper } from './style'
import { PagingInfoViewProps } from './interface'
import { isSmallLabCodesystem } from 'components/search/buckets/utils'
import { useLocation } from 'react-router-dom'

function PagingInfoView({
	className,
	end,
	searchTerm,
	start,
	totalResults,
	...rest
}: PagingInfoViewProps & React.HTMLAttributes<HTMLDivElement>) {
	const { pathname } = useLocation()
	return (
		<Wrapper
			className={appendClassName('sui-paging-info', className)}
			{...rest}
		>
			{searchTerm && !isSmallLabCodesystem(pathname) && (
				<>
					<strong>{totalResults}</strong> treff for{' '}
					<strong>{searchTerm}</strong>
				</>
			)}

			{isSmallLabCodesystem(pathname) && (
				<>
					<strong>{!searchTerm ? totalResults - 1 : totalResults}</strong> treff
					for <strong>{searchTerm}</strong>
				</>
			)}
		</Wrapper>
	)
}

export default PagingInfoView
