const paths = {
	icd10: '/icd10',
	icpc2: '/icpc2',
	nkpk: '/nkpk',
	phbu: '/phbu',
	snomed: '/snomed',
	adm: '/adm',
	norpat: '/norpat',
	main: '/',
	anatomisk: '/anatomisk',
	provemateriale: '/provemateriale',
	tekstligeresultatverdier: '/tekstligeresultatverdier',
	undersokelsesmetode: '/undersokelsesmetode',
	nlk: '/nlk',
	result: '/result',
}

export default paths
