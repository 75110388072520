import type { FacetViewProps } from '@elastic/react-search-ui-views'
import { appendClassName } from 'utils'
import { useEffect, useState } from 'react'
import { Checkbox, FilterWrapper } from 'components/common/elastic/style'

const AdmActiveFacet = ({
	className,
	label,
	options,
	onRemove,
	onSelect,
	values,
}: FacetViewProps) => {
	const trueOptions = options.find((option) => option.value === 'Utgått')
	const isSelected = values.includes('Utgått')

	// If no selections, initialize with all options selected except 'Utgått'
	useEffect(() => {
		if (options.every((option) => option.selected === false)) {
			onSelect([
				'Til høring',
				'Til utbredelse eller er i bruk',
				'Til utprøving',
				'Regional anbefaling',
				'Til anbefaling',
				'Under arbeid',
				'Utsatt til senere',
				'Utarbeides av andre enn KITH',
			])
		}
	}, [])

	const [isDisabled, setIsDisabled] = useState<boolean>(false)

	const handleToggle = () => {
		if (isSelected) {
			onRemove('Utgått')
		} else {
			onSelect([
				'Utgått',
				'Til høring',
				'Til utbredelse eller er i bruk',
				'Til utprøving',
				'Regional anbefaling',
				'Til anbefaling',
				'Under arbeid',
				'Utsatt til senere',
				'Utarbeides av andre enn KITH',
			])
		}
	}

	useEffect(() => {
		if (!trueOptions) {
			setIsDisabled(true)
		} else {
			setIsDisabled(false)
		}
	}, [trueOptions, isDisabled])

	return (
		<FilterWrapper className={appendClassName(className)}>
			<Checkbox
				type='checkbox'
				label={label}
				id={`facet-${label}`}
				name={`facet-${label}`}
				disabled={isDisabled}
				checked={isSelected}
				onChange={handleToggle}
				style={{ fontWeight: isSelected ? 'bold' : 'normal' }}
			/>
		</FilterWrapper>
	)
}

export default AdmActiveFacet
