import { withSearch } from '@elastic/react-search-ui'
import {
	MultiCheckboxFacet,
	type FacetContainerContext,
} from '@elastic/react-search-ui-views'
import { helpers, type Filter, type FacetValue } from '@elastic/search-ui'
import { ComponentType, useState } from 'react'
import { FacetContainerState, FacetViewProps } from '../interface'
import { accentFold } from '../utils'
import { FacetContainerProps, SelectedValue } from './interface'

const { markSelectedFacetValuesFromFilters } = helpers

const CustomFacet = ({
	addFilter,
	className,
	facets,
	field,
	filterType = 'all',
	filters,
	label,
	removeFilter,
	setFilter,
	view,
	show = 100,
	isFilterable = false,
	a11yNotify,
	sortAlphabetically,
	showDisabledOptions = true,
	...rest
}: FacetContainerProps & FacetContainerState) => {
	const [more, setMore] = useState<number>(show)
	const [searchTerm, setSearchTerm] = useState<string>('')

	const handleClickMore = (totalOptions: number) => {
		setMore((moreMore) => {
			let visibleOptionsCount = moreMore + 10
			const showingAll = visibleOptionsCount >= totalOptions
			if (showingAll) visibleOptionsCount = totalOptions

			a11yNotify('moreFilters', { visibleOptionsCount, showingAll })

			return visibleOptionsCount
		})
	}

	const handleFacetSearch = (newSearchTerm: string) => {
		setSearchTerm(newSearchTerm)
	}

	const facetsForField = facets[field]

	if (!facetsForField) return null

	const facet = facetsForField[0]

	let facetValues = markSelectedFacetValuesFromFilters(
		facet,
		filters as Filter[],
		field,
		filterType
	).data

	const selectedValues: SelectedValue[] = facetValues
		.filter((fv: { selected: boolean }) => fv.selected)
		.map((fv: { value: string }) => fv.value)

	if (searchTerm.trim()) {
		facetValues = facetValues.filter((option: FacetValue) => {
			let valueToSearch
			switch (typeof option.value) {
				case 'string':
					valueToSearch = accentFold(option.value).toLowerCase()
					break
				case 'number':
					valueToSearch = option.value.toString()
					break
				case 'object':
					valueToSearch =
						typeof option?.value === 'string'
							? accentFold(option.value).toLowerCase()
							: ''
					break

				default:
					valueToSearch = ''
					break
			}
			return valueToSearch.includes(accentFold(searchTerm).toLowerCase())
		})
	}

	const View: ComponentType<FacetViewProps> = view || MultiCheckboxFacet

	const viewProps: FacetViewProps = {
		className,
		label: label,
		onMoreClick: () => handleClickMore(facetValues.length),
		onRemove: (value) => {
			removeFilter(field, value, filterType)
		},
		onChange: (value) => {
			setFilter(field, value, filterType)
		},
		onSelect: (value) => {
			addFilter(field, value, filterType)
		},
		options: facetValues.slice(0, more),
		showMore: facetValues.length > more,
		showOptionsCount: show,
		values: selectedValues,
		showSearch: isFilterable!,
		onSearch: (value) => {
			handleFacetSearch(value)
		},
		searchPlaceholder: `Søk i ${label}`,
		sortAlphabetically: sortAlphabetically!,
		showDisabledOptions: showDisabledOptions!,
		...rest,
	}

	return <View {...viewProps} />
}

export default withSearch<FacetContainerProps, FacetContainerContext>(
	({ filters, facets, addFilter, removeFilter, setFilter, a11yNotify }) => ({
		filters,
		facets,
		addFilter,
		removeFilter,
		setFilter,
		a11yNotify,
	})
)(CustomFacet)
