import type { DefaultTheme } from 'styled-components'

/* Primary Old - Remove after acceptence test*/
// const blue = '#0069E8'
// const blueLighter = '#e3f0f3'
// const blueLight = '#bfe9f1'
// const aubergine = '#281C2C'
// const turquise = '#00BCD5'
// const aqua = '#037A94'
// const grey = '#EFEFEF'
// const greyShade = '#6C757D'
// const darkGrey = '#BBBBC2'
// const black = '#32323B'
// const white = '#ffffff'

/* eslint-disable */

/* FROM DESIGN SYSTEM */
/* Primary */
const Primary50 = '#F2F6F8'
const Primary100 = '#E6EEF0'
const Primary200 = '#CCDCE1'
const Primary400 = '#80A8B4'
const Primary700 = '#357487'
const Primary800 = '#206276'
const Primary900 = '#025169' //MainColor 1

/* Secondary */
const Secondary50 = '#F2F9FA'
const Secondary100 = '#E6F2F6'
const Secondary200 = '#CDE5ED'
const Secondary400 = '#81BFD1'
const Secondary500 = '#3699B6'
const Secondary700 = '#047FA4' //MainColor 2

/* Graytones */
const Grey50 = '#F7F7F7';
const Grey100 = '#EBECEC';
const Grey200 = '#D8DADA';
const Grey400 = '#A6A6A6';
const Grey600 = '#767676';
const Grey800 = '#504F4F';
const GreyBlack = '#212121';
const GreyWhite = '#FFFFFF'; //MainColor 3

/* Other */
const Support02800 = '#7C145C'
const Support03300 = '#FEF4CE'
const Support03100 = '#FFF9E6'
const Support01300 = '#95DCCA'
const Support04800 = '#0069E8'

const Red100 = '#FFD6E1'
const Red800 = '#FA8096'
const Yellow500 = '#F4C127'
const GrayBlack = '#212121'

/* eslint-enable */

/* Primary */
const blue = Primary900
const hardBlue = Support04800
const lightBlue = Secondary400
const blueLighter = Secondary100
const turquise = Secondary500 //Used as light-blue ?
const aqua = Secondary500
const grey = Grey100 //Used one time, DefaultResultView
const greyShade = Grey600
const black = GrayBlack
const white = GreyWhite
const lightGrey = Grey100

/* Secondary */
const darkPurple = Support02800 //used once in footer
const red = Red800 //used once
const yellow = Yellow500 //used once

/* Other */
const lightBeige = Support03100 //not in use, but exist in switch case
const lightYellow = Support03300 //used once and in switch case

/* STATUS */
const greenSignal = Support01300 //used once in toast
const blueSignal = Secondary100 //not in use, but exist in switch case
const redSignal = Red100 //used once in toast

/* eslint-disable */

const colours = {
	primary: {
		blue,
		hardBlue,
		blueLighter,
		turquise,
		aqua,
		grey,
		greyShade,
		black,
		white,
		Primary50,
		Primary100,
		Primary200,
		Primary400,
		Primary700,
		Primary800,
	},
	secondary: {
		darkPurple,
		red,
		yellow,
		lightBeige,
		lightYellow,
		lightGrey,
		lightBlue,
		greenSignal,
		blueSignal,
		redSignal,
		Secondary50,
		Secondary100,
		Secondary200,
		Secondary400,
		Secondary500,
		Secondary700,
	},
}

const fonts = {
	graphik: {
		regular: 'GraphikRegular',
		medium: 'GraphikMedium',
		semibold: 'GraphikSemiBold',
		bold: 'GraphikBold',
		light: 'GraphikLight',
	},
	headings: 'Work Sans, sans-serif',
	senserif: 'Inter, sans-serif',
}

const fontSizes = {
	bodyText: {
		xs: '12px',
		sm: '14px',
		md: '16px',
		lg: '18px',
		xl: '22px',
		xxl: '24px',
	},
	headings: {
		h5: '18px',
		h4: '20px',
		h3: '24px',
		h2: '28px',
		h1: '32px',
	},
}

const letterSpacing = {
	headings: '-0.02em',
	body: '0.02em',
	normal: 'normal',
}

const spacing = {
	xxs: '0.25rem',
	xs: '0.5rem',
	sm: '0.75rem',
	md: '1rem',
	lg: '1.5rem',
	xl: '2rem',
	xxl: '4rem',
}

const shadows = {
	xxs: 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
	xs: 'rgba(0, 0, 0, 0.05) 0px 1px 2px 0px',
	sm: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px',
	md: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px',
	lg: 'rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
	xl: 'rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px',
	xxl: 'rgba(0, 0, 0, 0.25) 0px 25px 50px -12px',
}

const settings = {
	gridMaxWidth: '1400px',
}

const breakpoints = {
	sm: '575px',
	md: '767px',
	lg: '991px',
	xl: '1199px',
	xxl: '1399px',
}

export const theme: DefaultTheme = {
	colours,
	settings,
	fonts,
	letterSpacing,
	shadows,
	fontSizes,
	spacing,
	breakpoints,
}
