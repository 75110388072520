import { ListGroup } from 'react-bootstrap'
import { styled } from 'styled-components'

export const ResultViewWrapper = styled(ListGroup.Item)<{
	$isDisabled: boolean
}>`
	border-bottom: 1px solid ${(props) => props.theme.colours.primary.aqua} !important;
	border: none;
	padding: 0.8rem 0.5rem;
	color: ${(props) => props.theme.colours.primary.black};
	cursor: ${(props) => (props.$isDisabled ? 'not-allowed' : 'pointer')};

	&:last-child {
		border-bottom: none;
	}
	&:hover {
		background-color: ${(props) => props.theme.colours.secondary.lightGrey};
		border-radius: 3px;
		color: ${(props) => props.theme.colours.primary.blue};
	}

	.tag {
		border-radius: 3px;
		padding: 0.2rem 0.4rem;
		font-size: 14px;
		word-break: keep-all;
	}
	.tag-yellow {
		background-color: ${(props) => props.theme.colours.secondary.lightYellow};
		border: 1px solid ${(props) => props.theme.colours.secondary.yellow};
	}

	.light-blue {
		background-color: ${(props) => props.theme.colours.secondary.lightBlue};
		border: 1px solid ${(props) => props.theme.colours.primary.turquise};
	}

	.blue {
		background-color: ${(props) => props.theme.colours.primary.blue};
		border: 1px solid ${(props) => props.theme.colours.primary.blue};
		color: ${(props) => props.theme.colours.primary.white};
	}

	.icon-blue {
		color: ${(props) => props.theme.colours.primary.blue};
	}
`
