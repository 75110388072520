import type { SearchResult } from '@elastic/search-ui/lib/esm/types'
import { Col } from 'react-bootstrap'
import { getRaw, getSnippet } from 'utils'
import { ResultViewWrapper, SearchResultBar } from './style'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { useStoreContext } from 'context/StoreProvider'
import { useNavigate } from 'react-router-dom'
import parse from 'html-react-parser'
import { withSearch } from '@elastic/react-search-ui'
import {
	highlightSearchTerm,
	processText,
} from 'components/search/buckets/utils'

interface SnomedResultViewProps {
	results: SearchResult
	searchTerm: string
}

const SnomedResultView = ({ results, searchTerm }: SnomedResultViewProps) => {
	const navigate = useNavigate()

	const { actions } = useStoreContext()

	const { updateCodeValue } = actions

	return (
		<SearchResultBar>
			{results.map((result: SearchResult) => {
				const subtermSnippetNotHighlighted =
					result.subterm.snippet?.indexOf('<em>') === -1

				const handleClickedResult = () => {
					navigate(`result/${getRaw(result, 'code')}`)
					updateCodeValue(getRaw(result, 'code'))
				}
				return (
					<ResultViewWrapper
						onClick={handleClickedResult}
						onKeyDown={(event) => {
							if (event.key === 'Enter' || event.key === ' ') {
								handleClickedResult()
							}
						}}
						role='button'
						tabIndex={0}
						key={getRaw(result, 'code')}
					>
						<div className='d-flex flex-column gap-1 gap-md-2'>
							<Col>
								{getRaw(result, 'name_norwegian') ? (
									<span className='fw-bold pb-1 text-break text-md-normal'>
										{processText('name_norwegian', searchTerm, false, result)}
									</span>
								) : (
									<span className='fw-bold pb-1 text-break text-md-normal'>
										{getRaw(result, 'name_english') &&
											processText('name_english', searchTerm, false, result)}
									</span>
								)}
							</Col>

							<Col
								xs='auto'
								className='d-flex align-items-end justify-content-between'
							>
								<span className='d-flex flex-column gap-3 flex-md-row'>
									{subtermSnippetNotHighlighted || // Subterm snippet is not highlighted
									(result.name_norwegian.raw &&
										result.name_norwegian.snippet?.indexOf('<em>') !== -1) || // Name norwegian snippet is highlighted
									(!result.name_norwegian.raw &&
										result.name_english.snippet?.indexOf('<em>') !==
											-1) ? null : (
										// Subterm snippet is highlighted, name norwegian and english are not
										<span className='fs-6'>
											{getSnippet(result, 'subterm') &&
												parse(
													highlightSearchTerm(
														getSnippet(result, 'subterm') as string,
														searchTerm
													) as string
												)}
										</span>
									)}

									<span>{processText('code', searchTerm, false, result)}</span>
								</span>
								<FontAwesomeIcon icon={faChevronRight} className='icon-blue' />
							</Col>
						</div>
					</ResultViewWrapper>
				)
			})}
		</SearchResultBar>
	)
}

export default withSearch(({ searchTerm, results }) => ({
	searchTerm,
	results,
}))(SnomedResultView)
