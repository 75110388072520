import { Button, Heading } from 'components/common'
import { Image } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { paths } from 'utils'
import { Paragraph } from './style'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'

const NotFound = () => {
	const navigate = useNavigate()
	const { t } = useTranslation()

	useEffect(() => {
		document.title = `${t('general.finnkode')} - ${t('notFound.title')}`
	}, [])

	return (
		<section className='d-flex flex-column gap-4 justify-content-center align-items-center mb-5'>
			<Image
				src='/assets/digitalt_helsedokument_ehelseillustrasjon.png'
				width='150px'
				alt={t('notFound.imageAlt')}
			/>
			<Heading
				variant='h1'
				className='font-medium text-center'
				id='not-found-heading'
			>
				{t('notFound.title')}
			</Heading>
			<Paragraph className=' text-center'>
				<p className='fs-5'>{t('notFound.text')}</p>

				<div className='d-flex align-items-center gap-3 justify-content-center flex-column flex-md-row mt-4'>
					<Button variant='secondary' onClick={() => navigate(-1)}>
						{t('notFound.goToPreviousPage')}
					</Button>
					<Button variant='blue' onClick={() => navigate(paths.main)}>
						{t('notFound.goToFrontpage')}
					</Button>
				</div>
			</Paragraph>
		</section>
	)
}

export default NotFound
