import capitalizeFirstLetter from './capitalizeFirstLetter'

function snippetFix(snippet: string) {
	if (!snippet) return ''

	const regex = /\([^\)]+$/
	if (snippet.match(regex)) {
		return snippet + ')'
	}

	return capitalizeFirstLetter(snippet)
}

export default snippetFix
