import { Button, Heading, LinkGroup } from 'components/common'
import { Col, Row } from 'react-bootstrap'
import { clinicalCodework, labCodework } from './data'
import { useTranslation } from 'react-i18next'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'
import { paths, scrollToTop } from 'utils'
import { Wrapper } from './style'
import { useEffect } from 'react'

const LinkSection = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()

	useEffect(() => {
		document.title = t('general.finnkode')
	}, [])

	return (
		<Wrapper className='gap-5 p-0'>
			{/* <hr className='mb-5' /> */}
			<Row className='gap-2 gap-lg-3 p-0 flex-column flex-md-row'>
				<Col className='link-section p-0' id='clinical-codesystems-list'>
					<Heading
						variant='h2'
						className='fs-3 mb-2 fw-bold'
						id='clinical-codesystems-heading'
					>
						{t('codesystems.clinicalCodework.title')}
					</Heading>
					<LinkGroup linkList={clinicalCodework} />
				</Col>
				<Col className='link-section p-0' id='lab-codesystems-list'>
					<Heading
						variant='h2'
						className='fs-3 mb-2 fw-bold'
						id='lab-codesystems-heading'
					>
						{t('codesystems.labCodework.title')}
					</Heading>
					<LinkGroup linkList={labCodework} />
				</Col>
			</Row>
			<Row
				className='align-items-end gap-4 big-link flex-column flex-md-row'
				onClick={() => {
					navigate(paths.adm)
					scrollToTop()
				}}
				id='adm-button-group'
			>
				<Col className='d-flex flex-column gap-2 p-0'>
					<span className='link-heading fs-4 fw-bold text-blue'>
						{t('codesystems.other.standarder.name')}
					</span>
					<span>{t('codesystems.other.standarder.description')}</span>
				</Col>
				<Col xs='auto' className='p-0'>
					<Button
						variant='secondary'
						size='md'
						onClick={() => navigate(paths.adm)}
						icon={faChevronRight}
						iconright
					>
						{`${t('general.goTo')} ${t('adminPage.name')}`}
					</Button>
				</Col>
			</Row>
			<Row
				className='align-items-end gap-4 snomed big-link flex-column flex-md-row'
				onClick={() => {
					navigate(paths.snomed)
					scrollToTop()
				}}
				id='snomed-button-group'
			>
				<Col className='d-flex flex-column gap-2 p-0'>
					<span className='link-heading fs-4 fw-bold text-blue'>
						{t('codesystems.other.snomedct.name')}
					</span>
					<span>{t('codesystems.other.snomedct.description')}</span>
				</Col>
				<Col xs='auto' className='p-0'>
					<Button
						variant='secondary'
						size='md'
						onClick={() => navigate(paths.snomed)}
						icon={faChevronRight}
						iconright
					>
						{`${t('general.goTo')} ${t('snomedctPage.name')}`}
					</Button>
				</Col>
			</Row>
		</Wrapper>
	)
}

export default LinkSection
