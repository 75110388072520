import AccordionItem from './AccordionItem'
import { AccordionProps } from './interface'
import { AccordionWrapper } from './style'

const Accordion = ({ data }: AccordionProps) => {
	return (
		<AccordionWrapper flush>
			{data.map((item, index) => {
				const { headerTitle, body, onClick } = item
				return (
					<AccordionItem
						key={index.toString()}
						eventKey={index.toString()}
						headerTitle={headerTitle}
						body={body}
						onClick={onClick}
					/>
				)
			})}
		</AccordionWrapper>
	)
}

export default Accordion
