import { withSearch } from '@elastic/react-search-ui'
import type { FilterType, FilterValue, SearchResult } from '@elastic/search-ui'
import { Accordion, Table } from 'react-bootstrap'
import NLKResultView from '../NLKResultView'
import { getRaw } from 'utils'
import { useTranslation } from 'react-i18next'
import { TableWrapper } from './style'
import { useEffect } from 'react'
import { useStoreContext } from 'context/StoreProvider'
import { isMobile } from 'react-device-detect'

interface NLKTableProps {
	results: SearchResult
	searchTerm: string
	setSearchTerm: (searchTerm: string) => void
	addFilter: (
		name: string,
		value: FilterValue,
		type?: FilterType | undefined
	) => void
}

const NLKTable = ({
	results,
	searchTerm,
	setSearchTerm,
	addFilter,
}: NLKTableProps) => {
	const { t } = useTranslation()
	const { state, actions } = useStoreContext()
	const { showInactiveOnLoad } = state
	const { updateShowInactiveOnLoad } = actions

	useEffect(() => {
		if (showInactiveOnLoad) {
			addFilter('active', ['false', 'true'], 'any')
			updateShowInactiveOnLoad(false)
		}
	}, [])

	const headerHeight = document.getElementById('site-header')?.clientHeight || 0
	const searchWrapperHeight =
		document.getElementById('search-wrapper')?.clientHeight || 0
	const metaBarHeight =
		document.getElementById('result-meta-bar')?.clientHeight || 0

	const calculateHeight = headerHeight + searchWrapperHeight + metaBarHeight + 1

	const isMobileOrSmallScreen = isMobile || window.innerWidth < 992
	return (
		<TableWrapper $topValue={`${isMobileOrSmallScreen ? 0 : calculateHeight}`}>
			<Table responsive={'md'}>
				<thead>
					<tr>
						<th colSpan={2} scope='col'>
							{t('nlkPage.properties.nameNorwegian')}
						</th>
						<th>{t('nlkPage.properties.code')}</th>
						<th>{t('nlkPage.properties.codeDefinition')}</th>
						<th align='right'>{t('general.status')}</th>
					</tr>
				</thead>
				<Accordion as={'tbody'} alwaysOpen defaultActiveKey={[]}>
					{Array.isArray(results) &&
						results.map((result: SearchResult, index: number) => {
							return (
								<NLKResultView
									key={getRaw(result, 'code') as string}
									result={result}
									eventKey={index.toString()}
									searchTerm={searchTerm}
									setSearchTermErstatt={setSearchTerm}
								/>
							)
						})}
				</Accordion>
			</Table>
		</TableWrapper>
	)
}

export default withSearch(
	({ results, searchTerm, setSearchTerm, addFilter }) => ({
		results,
		searchTerm,
		setSearchTerm,
		addFilter,
	})
)(NLKTable)
