import type { FacetViewProps } from '@elastic/react-search-ui-views'
import { appendClassName } from 'utils'
import { useEffect, useState } from 'react'
import { Checkbox, FilterWrapper } from 'components/common/elastic/style'

const BooleanFacet = ({
	className,
	label,
	options,
	onRemove,
	onSelect,
	values,
}: FacetViewProps) => {
	const trueOptions = options.find((option) => option.value === 'true')
	const isSelected = values.includes('true')

	const [isDisabled, setIsDisabled] = useState<boolean>(false)

	const handleToggle = () => {
		if (isSelected) {
			onRemove('true')
		} else {
			onSelect('true')
		}
	}

	useEffect(() => {
		if (!trueOptions) {
			setIsDisabled(true)
		} else {
			setIsDisabled(false)
		}
	}, [trueOptions, isDisabled])

	return (
		<FilterWrapper className={appendClassName(className)}>
			<Checkbox
				type='checkbox'
				label={label}
				id={`facet-${label}`}
				name={`facet-${label}`}
				disabled={isDisabled}
				checked={isSelected}
				onChange={handleToggle}
				style={{ fontWeight: isSelected ? 'bold' : 'normal' }}
			/>
		</FilterWrapper>
	)
}

export default BooleanFacet
