import { autocompleteQuery, connector, routingOptions } from './settings'

const generalConfig = {
	apiConnector: connector,
	alwaysSearchOnInitialLoad: true,
	hasA11yNotifications: false,
	autocompleteQuery: autocompleteQuery,
	routingOptions: routingOptions,
}

export default generalConfig
