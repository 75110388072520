import { SearchBox, SearchProvider } from '@elastic/react-search-ui'
import { generalConfig } from './config'
import { ElasticWrapper, InputView, Heading, Options } from 'components/common'
import { useTranslation } from 'react-i18next'
import { useStoreContext } from 'context/StoreProvider'
import { useEffect, useLayoutEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import {
	codesystems,
	codesystemsEndpointName,
	getCodeSystem,
	getCodeSystemEndpoint,
	getCodeSystemName,
	getCodeSystemOid,
	paths,
} from 'utils'
import { BodyResults, CodesystemTabNav, TabView } from './common'
import { isClinicalCodesystem, isSmallLabCodesystem } from '../utils'
import { searchQuery, searchFieldsIcd10 } from './config/settings'
import type { FilterType, FilterValue } from '@elastic/search-ui'
import { SearchSectionWrapper } from './style'

const SearchWithFilter = () => {
	const { t } = useTranslation()

	const { codeValue } = useParams<{ codeValue: string }>()

	const { state, actions } = useStoreContext()
	const { codeSystem, excludedSearchFields } = state
	const { updateCodeSystem, updateCodeValue, updateExcludedSearchFields } =
		actions

	const { pathname } = useLocation()

	useEffect(() => {
		const kodeverk = getCodeSystemName(codeSystem as string)
		document.title = `${t('general.finnkode')} - ${kodeverk}`

		// Remove any excluded search fields when switching code system
		updateExcludedSearchFields([])
	}, [codeSystem])

	useLayoutEffect(() => {
		updateCodeSystem(getCodeSystemEndpoint(pathname) as string)
	}, [])

	/* This must exist in a compoent that is parent to other components. 
			Sets the code used for almost all functionality in the app.
			Do not remove. */
	useEffect(() => {
		updateCodeValue(codeValue as string)
	}, [codeValue])

	const handleSetCodesystem = () => {
		if (pathname.includes(paths.norpat)) {
			return codesystems.apat, codesystems.norpat
		} else {
			return getCodeSystem(pathname) as string
		}
	}

	// The functionality below allows removing and adding search fields for the ADM code system
	// Can be generalized for any code system if needed in the future.
	const removedAdmSearchFields =
		codeSystem === codesystemsEndpointName.adm ? excludedSearchFields : []

	const addAdmSearchFields = (searchQueryObject: any) => {
		if (codeSystem !== codesystemsEndpointName.adm) return searchQueryObject

		const newSearchQuery = { ...searchQueryObject }
		newSearchQuery.search_fields = { ...newSearchQuery.search_fields }

		newSearchQuery.search_fields.definition = { weight: 1 }

		return newSearchQuery
	}

	const removeExcludedSearchFields = (searchQueryObject: any) => {
		if (removedAdmSearchFields.length > 0) {
			const newSearchQuery = { ...searchQueryObject }
			newSearchQuery.search_fields = { ...newSearchQuery.search_fields }

			for (const field of removedAdmSearchFields) {
				delete newSearchQuery.search_fields[field]
			}

			return addAdmSearchFields(newSearchQuery)
		}
		return addAdmSearchFields(searchQueryObject)
	}

	const addIcd10SearchOptions = (searchQueryObject: any) => {
		if (codeSystem === codesystemsEndpointName.icd10) {
			const newSearchQuery = { ...searchQueryObject }
			newSearchQuery.precision = 4
			newSearchQuery.search_fields = searchFieldsIcd10
			return newSearchQuery
		}
		return searchQueryObject
	}

	const modifySearchQueryIfNeeded = (searchQueryObject: any) => {
		const step1 = addIcd10SearchOptions(searchQueryObject)
		const step2 = removeExcludedSearchFields(step1)
		return step2
	}

	return (
		<>
			{/* max-heght:90vh */}
			<div className=' p-2'>
				{isClinicalCodesystem(pathname) || isSmallLabCodesystem(pathname) ? (
					// Tab navigation between code systems at top of page
					<CodesystemTabNav />
				) : null}

				{/* SearchProvider is a wrapper for the search functionality.
				It encapsulates searchTerm, results, and other elasticsearch data
				in a context that can be access by child components using the withSearch HOC.
				For an example see export statement of TabView component.
			*/}
				<SearchProvider
					config={{
						initialState: {
							searchTerm: '',
							resultsPerPage: isSmallLabCodesystem(pathname) ? 350 : 20,
						},
						searchQuery: {
							filters: [
								{
									field: 'code_system',
									values: [handleSetCodesystem() as FilterValue],
									type: 'any' as FilterType,
								},
								{
									field: 'active',
									values: ['true' as FilterValue],
									type: 'any' as FilterType,
								},
							],
							...modifySearchQueryIfNeeded(searchQuery),
						},
						...generalConfig,
					}}
				>
					<ElasticWrapper>
						<SearchSectionWrapper id='search-wrapper' className='p-2'>
							<div
								className='d-flex gap-3 justify-content-between p-0'
								id='searchOption'
							>
								<label
									id={`${codeSystem}-search-label`}
									htmlFor={`${codeSystem}-search-input`}
								>
									{/* Heading above search bar */}
									<Heading
										variant='h1'
										className='fs-4 fw-bold fs-md-3 mb-2'
										id={`${codeSystem}-page-title`}
									>
										{pathname.includes(paths.norpat)
											? t('norpatPage.title')
											: t('general.searchTitle', {
													term: getCodeSystemName(codeSystem as string),
											  })}{' '}
										{getCodeSystemOid(codeSystem as string) !== '' && (
											<span style={{ color: 'gray', fontWeight: 'normal' }}>
												({getCodeSystemOid(codeSystem as string)})
											</span>
										)}
									</Heading>
								</label>
								<Options /> {/* Flere valg meny */}
							</div>

							{/* Search bar */}
							<SearchBox
								searchAsYouType
								autocompleteResults={false}
								autocompleteSuggestions={false}
								shouldClearFilters={false}
								debounceLength={500}
								inputView={InputView}
							/>
						</SearchSectionWrapper>
						{isSmallLabCodesystem(pathname) ? <BodyResults /> : <TabView />}
						{/* Results (everything under search bar) */}
					</ElasticWrapper>
				</SearchProvider>
			</div>
		</>
	)
}

export default SearchWithFilter
