import { Form } from 'react-bootstrap'
import { styled } from 'styled-components'

export const Checkbox = styled(Form.Check)`
	font-size: 16px;
	color: ${(props) => props.theme.colours.primary.black};
	cursor: pointer;
`

export const FilterWrapper = styled.fieldset<{ $isDisabled?: boolean }>`
	label {
		display: unset;
		cursor: pointer;
		padding-left: 0 !important;
		color: ${(props) =>
			props.$isDisabled
				? props.theme.colours.primary.greyShade
				: props.theme.colours.primary.black};
	}
	.sui-multi-checkbox-facet {
		display: flex;
		flex-flow: column wrap;
		gap: 0.2rem;
		margin: 0;
	}
	.sui-facet-search {
		margin-bottom: 10px;
	}
	.sui-facet-view-more {
		display: block;
		margin: 0 auto;
		border: 1px solid #ddd;
		padding: 5px 10px;
		border-radius: 5px;
		background-color: #eee;
		cursor: pointer;
	}

	.form-check {
		display: flex;
		gap: 0.5rem;
	}

	.form-check-input {
		border-color: ${(props) => props.theme.colours.primary.black};
		border-width: 2px;
		width: 1.4rem;
		height: 1.4rem;
		cursor: pointer;
		transition: all 0.2s ease-in-out;
		&:hover {
			border-color: ${(props) => props.theme.colours.primary.blue};
		}
	}
	.form-check-input:checked {
		background-color: ${(props) => props.theme.colours.primary.blue};
		border-color: ${(props) => props.theme.colours.primary.black};
	}

	.form-check-label {
		font-size: 16px;
		color: ${(props) => props.theme.colours.primary.black};
		cursor: pointer;
		padding-top: 0.2rem;
	}

	.is-checked {
		font-weight: bold;
	}

	.total-records {
		background-color: ${(props) => props.theme.colours.primary.blueLighter};
		color: ${(props) => props.theme.colours.primary.black};
		border-radius: 10px;
		font-weight: bold;
		padding: 0.2rem 0.4rem;
		font-size: 12px;
	}

	.btn-primary {
		color: ${(props) => props.theme.colours.primary.blue};
	}
	.card {
		border: none !important;
		padding: 0 !important;
		.card-header {
			background-color: ${(props) => props.theme.colours.primary.white};
			border: none;
			padding: 0 !important;
			border-bottom: 1px solid ${(props) => props.theme.colours.primary.black};
			button {
				padding: 0 !important;
				display: flex !important;
				width: 100%;
				align-items: center !important;
				justify-content: space-between !important;
				align-content: center;
			}

			legend {
				margin-bottom: 0;
			}
		}
		.card-body {
			display: flex;
			flex-direction: column;
			gap: 0.2rem;
			padding: 1rem 0;
		}
	}

	.filter-count {
		background-color: ${(props) => props.theme.colours.primary.blueLighter};
		color: ${(props) => props.theme.colours.primary.black};
		border-radius: 10px;
		font-weight: bold;
		padding: 0.2rem 0.4rem;
		font-size: 12px;
	}
`
