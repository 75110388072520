import { Col } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import {
	chapterString,
	codesystems,
	collectionsString,
	getRaw,
	getSnippet,
} from 'utils'
import { Tag } from 'components/common'
import { useStoreContext } from 'context/StoreProvider'
import { ResultViewWrapper } from './style'
import type { SearchResult } from '@elastic/search-ui'
import {
	getStatusText,
	getTagColor,
	handleNavigate,
	processText,
	highlightSearchTerm,
} from 'components/search/buckets/utils'
import parse from 'html-react-parser'

const DefaultResultView = ({ result, searchTerm }: SearchResult) => {
	const navigate = useNavigate()
	const { pathname } = useLocation()

	const { actions, state } = useStoreContext()

	const { code, scrollRef } = state

	const { updateCodeValue } = actions

	const shouldBeDisabled =
		result.active.raw === 'false' && result.code_system.raw !== codesystems.adm

	const handleScrollOnClick = () => {
		const scrollToElement = () => {
			const element = scrollRef.current[code as string]
			if (element) {
				element.scrollIntoView({ behavior: 'smooth', block: 'center' })
			}
		}

		const delay = 500

		const timeoutId = setTimeout(scrollToElement, delay)

		return () => clearTimeout(timeoutId)
	}

	const handleOnClick = (event: React.MouseEvent<HTMLInputElement>) => {
		event.preventDefault() // fixes a warning in the browser
		if (result.code_system.raw === codesystems.adm) {
			navigate(
				`${handleNavigate(result.code_system.raw)}/${collectionsString}/${
					result.compact_code.raw.split(' ')[0]
				}`
			)
			updateCodeValue(result.compact_code.raw.split(' ')[0])
		} else if (result.code_system.raw === codesystems.icpc2b) {
			navigate(
				`${handleNavigate(result.code_system.raw)}/${chapterString}/${
					result.parent_code.raw
				}/?q=${searchTerm}`
			)
			updateCodeValue(result.parent_code.raw)
		} else if (
			result.code_system.raw === codesystems.nlk ||
			result.code_system.raw === codesystems.anatomisk ||
			result.code_system.raw === codesystems.tekstligeresultatverdier ||
			result.code_system.raw === codesystems.undersokelsesmetode ||
			result.code_system.raw === codesystems.provemateriale
		) {
			navigate(
				`${handleNavigate(result.code_system.raw)}/${result.code.raw}/?q=${
					result.code.raw
				}`
			)
			updateCodeValue(result.code.raw)
		} else {
			if (shouldBeDisabled) {
				return null
			} else {
				navigate(
					`${handleNavigate(result.code_system.raw)}/${chapterString}/${
						result.code.raw
					}/?q=${searchTerm}`
				)
				updateCodeValue(result.code.raw)
			}
		}
		handleScrollOnClick()
	}
	return (
		<ResultViewWrapper
			action={!shouldBeDisabled}
			onClick={handleOnClick}
			$isDisabled={shouldBeDisabled}
		>
			<div className='d-flex flex-column gap-1 gap-md-2 py-2 px-1'>
				{getRaw(result, 'code_system') === codesystems.adm ? (
					<>
						<Col xs='auto'>
							<span className='pb-1 text-wrap'>
								{processText('name_norwegian', searchTerm, false, result)}
							</span>
						</Col>
						<Col className='d-flex gap-2 align-items-center' xs='auto'>
							<span className='fs-6'>
								{getSnippet(result, 'code') &&
									parse(
										highlightSearchTerm(
											result.code.snippet?.indexOf('<em>') === -1
												? result.compact_code.raw.substring(0, 4)
												: result.code.snippet === null
												? getRaw(result, 'compact_code')?.substring(0, 4)
												: getSnippet(result, 'code'),
											searchTerm
										) as string
									)}
							</span>
							{result.status_text.raw && (
								<span className='d-flex gap-2 flex-wrap ms-auto'>
									<Tag
										color='blue'
										text={highlightSearchTerm(
											getSnippet(result, 'parent_code')?.slice(4) as string,
											searchTerm
										)}
									/>
									{getRaw(result, 'status_text') &&
										getRaw(result, 'status_text') ===
											'InaForeldet og erstattet av annet kodeverkktiv' && (
											<Tag
												color={getTagColor(
													getRaw(result, 'status_text') as string
												)}
												text={getStatusText(
													getRaw(result, 'status_text') as string
												)}
											/>
										)}
								</span>
							)}
						</Col>
					</>
				) : (
					<>
						<Col xs='auto'>
							<span className='pb-1 text-break text-md-normal'>
								{processText('name_norwegian', searchTerm, false, result)}
							</span>
						</Col>
						<Col className='d-flex gap-2 align-items-center' xs='auto'>
							<span className='fs-6'>
								{processText('code', searchTerm, false, result)}
							</span>
							<span className='d-flex gap-2 flex-wrap ms-auto'>
								{getRaw(result, 'is_apat') &&
									getRaw(result, 'is_apat') === 'true' && (
										<span className='d-flex flex-column gap-3 flex-md-row'>
											<Tag color={'yellow'} text={codesystems.apat} />
										</span>
									)}
								{pathname === '/' ? (
									<Tag color='blue' text={result.code_system.snippet} />
								) : null}
							</span>
						</Col>
					</>
				)}
			</div>
		</ResultViewWrapper>
	)
}

export default DefaultResultView
