import type { FilterValue } from '@elastic/search-ui'

export default function getFilterValueDisplay(
	filterValue: FilterValue
): string {
	if (filterValue === undefined || filterValue === null) return ''
	if (filterValue === 'true') return 'Aktiv'
	if (filterValue === 'false') return 'Inaktiv'
	if (typeof filterValue === 'object' && 'name' in filterValue)
		return filterValue.name
	return String(filterValue)
}
