import Select from 'react-select'
import type { StylesConfig, GroupBase } from 'react-select'
import { appendClassName } from 'utils'
import { Wrapper } from './style'
import { OptionType, ResultsPerPageViewProps } from './interface'

const setDefaultStyle: StylesConfig<
	OptionType,
	false,
	GroupBase<OptionType>
> = {
	option: (styles, { isDisabled, isFocused, isSelected }) => {
		return {
			...styles,
			fontSize: '16px',
			backgroundColor: isDisabled
				? undefined
				: isSelected
				? 'white'
				: isFocused
				? 'white'
				: undefined,
			color: isDisabled
				? '#ccc'
				: isSelected
				? '#32323B'
					? '#32323B'
					: '#32323B'
				: '#32323B',
			cursor: isDisabled ? 'not-allowed' : 'default',

			':active': {
				...styles[':active'],
				backgroundColor: !isDisabled
					? isSelected
						? 'white'
						: 'white'
					: undefined,
			},
		}
	},
	control: (styles) => ({ ...styles, backgroundColor: 'white' }),
	input: (styles) => ({ ...styles }),
	placeholder: (styles) => ({ ...styles }),
	singleValue: (styles) => ({ ...styles }),
}

const wrapOption = (option: number) => ({
	label: option.toString(),
	value: option.toString(),
})

const ResultsPerPageView = ({
	className,
	onChange,
	options,
	value: selectedValue,
	...rest
}: ResultsPerPageViewProps) => {
	let selectedOption = null

	if (selectedValue) {
		selectedOption = wrapOption(selectedValue)

		if (!options?.includes(selectedValue))
			options = [selectedValue, ...(options as number[])]
	}

	return (
		<Wrapper
			className={appendClassName('sui-results-per-page', className)}
			{...rest}
		>
			<label
				className='sui-results-per-page__label'
				id='results-per-page-label'
				htmlFor='results-per-page'
			>
				Viser
			</label>
			<Select
				className='sui-select sui-select--inline'
				id='results-per-page'
				aria-labelledby='results-per-page-label'
				classNamePrefix='sui-select'
				aria-label='Velg antall resultater per side'
				value={selectedOption}
				onChange={(o) => {
					if (o !== null) {
						onChange(parseInt(o.value, 10)) // convert to number
					}
				}}
				options={options?.map(wrapOption)}
				isSearchable={false}
				styles={setDefaultStyle}
			/>
		</Wrapper>
	)
}

export default ResultsPerPageView
