import resultFields from './resultFields'
import searchFields from './searchFields'

const searchQuery = {
	disjunctiveFacets: [
		'code_system',
		'active',
		'status_text',
		'owner',
		'reportable_npr',
		'additional_code',
		'star_code',
		'is_apat',
		'owner_name',
		'chapter_code',
	],
	facets: {
		code_system: {
			type: 'value',
		},
		active: {
			type: 'value',
		},
		status_text: {
			type: 'value',
		},
		owner: {
			type: 'value',
		},
		reportable_npr: {
			type: 'value',
		},
		additional_code: {
			type: 'value',
		},
		star_code: {
			type: 'value',
		},
		is_apat: {
			type: 'value',
		},
		owner_name: {
			type: 'value',
		},
		chapter_code: {
			type: 'value',
		},
	},
	search_fields: searchFields,

	result_fields: resultFields,
}

export default searchQuery
