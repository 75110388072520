import { codesystemsEndpointName } from 'utils'

const smallLabCodesystems = [
	codesystemsEndpointName.anatomisk,
	codesystemsEndpointName.tekstligeresultatverdier,
	codesystemsEndpointName.provemateriale,
	codesystemsEndpointName.undersokelsesmetode,
]

const isSmallLabCodesystem = (pathname: string) => {
	return smallLabCodesystems.some((labCodesystem) =>
		pathname.includes(labCodesystem)
	)
}

export default isSmallLabCodesystem
