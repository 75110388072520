import codesystemsEndpointName from 'utils/constants/codesystemsEndpointName'
import codesystems from 'utils/constants/codesystems'

const codeSystemMap: Record<string, string> = {
	[codesystems.icd10]: codesystemsEndpointName.icd10,
	[codesystems.icpc2]: codesystemsEndpointName.icpc2,
	[codesystems.icpc2b]: codesystemsEndpointName.icpc2b,
	[codesystems.nkpk]: codesystemsEndpointName.nkpk,
	[codesystems.phbu]: codesystemsEndpointName.phbu,
	[codesystems.norpat]: codesystemsEndpointName.norpat,
	[codesystems.apat]: codesystemsEndpointName.apat,
	[codesystems.nlk]: codesystemsEndpointName.nlk,
	[codesystems.adm]: codesystemsEndpointName.adm,
	[codesystems.provemateriale]: codesystemsEndpointName.provemateriale,
	[codesystems.anatomisk]: codesystemsEndpointName.anatomisk,
	[codesystems.snomedct]: codesystemsEndpointName.snomedct,
	[codesystems.tekstligeresultatverdier]:
		codesystemsEndpointName.tekstligeresultatverdier,
	[codesystems.undersokelsesmetode]:
		codesystemsEndpointName.undersokelsesmetode,
}

const getCodeSystemString = (value: string) => {
	return codeSystemMap[value]
}

export default getCodeSystemString
