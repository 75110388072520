import { codesystemsEndpointName } from 'utils'

const clinicalCodesystem = [
	codesystemsEndpointName.icd10,
	codesystemsEndpointName.icpc2,
	codesystemsEndpointName.nkpk,
	codesystemsEndpointName.phbu,
]

const isClinicalCodesystem = (pathname: string) => {
	return clinicalCodesystem.some((codesystem) => pathname.includes(codesystem))
}

export default isClinicalCodesystem
