import AppSearchAPIConnector from '@elastic/search-ui-app-search-connector'

const searchKey = process.env.REACT_APP_ELASTIC_SEARCH_KEY
const engineName = process.env.REACT_APP_ELASTIC_CODESYSTEMS_ENGINE_NAME //'ehelse-kodeverk-ngram-synonym'
const endpointBase = process.env.REACT_APP_ELASTIC_ENDPOINT_BASE

const connector = new AppSearchAPIConnector({
	searchKey: searchKey as string,
	engineName: engineName as string,
	endpointBase: endpointBase as string,
})

export default connector
