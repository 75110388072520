import { HEADING, HeadingProps } from './interface'
import { StyledH1, StyledH2, StyledH3, StyledH4, StyledH5 } from './style'
import { useId } from 'react'

const Heading = ({
	id,
	variant,
	className,
	children,
	onClick,
	style,
	...props
}: HeadingProps) => {
	const fallbackId = useId()
	switch (variant) {
		case HEADING.H1:
			return (
				<StyledH1
					id={id || fallbackId}
					className={className}
					onClick={onClick}
					style={style}
					{...props}
				>
					{children}
				</StyledH1>
			)
		case HEADING.H2:
			return (
				<StyledH2
					id={id || fallbackId}
					className={className}
					onClick={onClick}
					style={style}
					{...props}
				>
					{children}
				</StyledH2>
			)
		case HEADING.H3:
			return (
				<StyledH3
					id={id || fallbackId}
					className={className}
					onClick={onClick}
					style={style}
					{...props}
				>
					{children}
				</StyledH3>
			)
		case HEADING.H4:
			return (
				<StyledH4
					id={id || fallbackId}
					className={className}
					onClick={onClick}
					style={style}
					{...props}
				>
					{children}
				</StyledH4>
			)
		case HEADING.H5:
			return (
				<StyledH5
					id={id || fallbackId}
					className={className}
					onClick={onClick}
					style={style}
					{...props}
				>
					{children}
				</StyledH5>
			)
		default:
			return (
				<StyledH1
					id={id || fallbackId}
					className={className}
					onClick={onClick}
					style={style}
					{...props}
				>
					{children}
				</StyledH1>
			)
	}
}

export default Heading
