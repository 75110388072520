import { codesystemsEndpointName, paths } from 'utils'

const getCodeSystem = (value: string) => {
	switch (value) {
		case codesystemsEndpointName.icd10:
			return paths.icd10
		case codesystemsEndpointName.icpc2:
			return paths.icpc2
		case codesystemsEndpointName.nkpk:
			return paths.nkpk
		case codesystemsEndpointName.phbu:
			return paths.phbu
		case codesystemsEndpointName.norpat:
			return paths.norpat
		default:
			return ''
	}
}

export default getCodeSystem
