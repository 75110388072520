import { SearchProvider, WithSearch } from '@elastic/react-search-ui'
import { nlkConfig } from './config/nlkConfig'
import { ElasticWrapper } from 'components/common'
import { getCodeSystem, resultsPerPage } from 'utils'
import { useTranslation } from 'react-i18next'
import { useStoreContext } from 'context/StoreProvider'
import { useEffect, useLayoutEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { NLKTable, NLKFilter } from './common'
import { SearchPageLayout } from '../common'

const NLKSearch = () => {
	const { t } = useTranslation()

	const { state, actions } = useStoreContext()
	const { code } = state
	const { updateCodeSystem } = actions

	const { pathname } = useLocation()

	useEffect(() => {
		document.title = `${t('general.finnkode')} - ${t(
			'codesystems.labCodework.nlk'
		)}`
	}, [])

	useLayoutEffect(() => {
		updateCodeSystem(getCodeSystem(pathname) as string)
	}, [pathname, updateCodeSystem])

	return (
		<SearchProvider
			config={{
				initialState: {
					searchTerm: code ?? '',
					resultsPerPage: resultsPerPage,
				},
				...nlkConfig,
			}}
		>
			<WithSearch
				mapContextToProps={({
					searchTerm,
					results,
					isFetching,
					isLoading,
				}) => ({
					searchTerm,
					results,
					isFetching,
					isLoading,
				})}
			>
				{({ results, isFetching, searchTerm, isLoading }) => {
					return (
						<ElasticWrapper className='d-flex flex-column'>
							<SearchPageLayout
								headingLabel={t('nlkPage.title')}
								results={results}
								searchTerm={searchTerm}
								isFetching={isFetching}
								isLoading={isLoading}
								hideSidebar={false}
								bodyResults={<NLKTable />}
								sidebarContent={<NLKFilter />}
							/>
						</ElasticWrapper>
					)
				}}
			</WithSearch>
		</SearchProvider>
	)
}

export default NLKSearch
