import { codesystems, paths } from 'utils'

const codeSystemPaths = {
	[codesystems.icd10]: paths.icd10,
	[codesystems.icpc2]: paths.icpc2,
	[codesystems.icpc2b]: paths.icpc2,
	[codesystems.nkpk]: paths.nkpk,
	[codesystems.phbu]: paths.phbu,
	[codesystems.norpat]: paths.norpat,
	[codesystems.apat]: paths.norpat,
	[codesystems.adm]: paths.adm,
	[codesystems.anatomisk]: paths.anatomisk,
	[codesystems.provemateriale]: paths.provemateriale,
	[codesystems.tekstligeresultatverdier]: paths.tekstligeresultatverdier,
	[codesystems.undersokelsesmetode]: paths.undersokelsesmetode,
	[codesystems.nlk]: paths.nlk,
}

const handleNavigate = (codeSystem: string) => {
	return codeSystemPaths[codeSystem] || '/'
}

export default handleNavigate
