import {
	ClearFilters,
	CustomFacet,
	CustomFilter,
	FilterTitle,
	ReverseBooleanFacet,
} from 'components/common'
import { useTranslation } from 'react-i18next'

const GeneralFilter = () => {
	const { t } = useTranslation()
	return (
		<>
			<div className='pt-3'>
				<FilterTitle
					hasBorderBottom={true}
					className='d-flex justify-content-between'
				>
					{t('frontpage.search.filter')}
					<ClearFilters />
				</FilterTitle>

				<CustomFacet
					field='active'
					label={t('search.filters.showInactiveCodes')}
					filterType='any'
					view={ReverseBooleanFacet}
				/>
			</div>

			<CustomFacet
				field='code_system'
				label={t('frontpage.search.codesystem')}
				view={CustomFilter}
				filterType='any'
				isFilterable
			/>
		</>
	)
}

export default GeneralFilter
