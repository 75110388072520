import { ListGroup } from 'react-bootstrap'
import { styled } from 'styled-components'

export const ResultViewWrapper = styled(ListGroup.Item)<{
	$isDisabled: boolean
}>`
	border-bottom: 1px solid ${(props) => props.theme.colours.primary.grey} !important;
	border: none;
	padding: 0.5rem;
	cursor: pointer;
	color: ${(props) =>
		props.$isDisabled
			? props.theme.colours.primary.greyShade
			: props.theme.colours.primary.black};

	&:last-child {
		border-bottom: none;
	}
	&:hover {
		background-color: #f9f9f9;
		border-radius: 3px;
	}

	.search-result-line {
		padding: 1rem 1.5rem;
		cursor: ${(props) => (props.$isDisabled ? 'not-allowed' : 'pointer')};
		color: ${(props) =>
			props.$isDisabled
				? props.theme.colours.primary.greyShade
				: props.theme.colours.primary.black};

		&:hover {
			background-color: #f9f9f9;
			border-radius: 5px;
		}
	}
`
