import parse from 'html-react-parser'
import { formatDate, getRaw, getSnippet } from 'utils'
import highlightSearchTerm from './highlightSearchTerm'
import type { SearchResult } from '@elastic/search-ui'

const isDate = (text: string) => {
	const datePattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}$/
	return datePattern.test(text) && !isNaN(Date.parse(text))
}

const processText = (
	field: string,
	searchTerm: string,
	useSnippet: boolean,
	result: SearchResult
) => {
	// Get the raw or snippet text
	const rawOrSnippetText = useSnippet
		? getSnippet(result, field)
		: getRaw(result, field)

	if (typeof rawOrSnippetText !== 'string') {
		return null
	}

	// If rawOrSnippetText is a date, format it
	const formattedText = isDate(rawOrSnippetText as string)
		? formatDate(rawOrSnippetText as string)
		: rawOrSnippetText

	// If searchTerm is empty or undefined, return the original text
	if (!searchTerm) {
		return parse(formattedText)
	}

	// Highlight the search term in the formatted text
	const highlightedText = highlightSearchTerm(
		formattedText as string,
		searchTerm
	)

	// Parse the highlighted text into React elements
	const parsedText = parse(highlightedText)

	return parsedText
}

export default processText
