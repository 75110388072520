import snippetFix from './snippetFix'

const getSnippet = (
	result: { [x: string]: { snippet: string } },
	value: string | number
) => {
	if (!result[value] || !result[value].snippet) return
	return snippetFix(result[value].snippet)
}

export default getSnippet
