const ehelseUrl = 'https://www.ehelse.no'
const ehelseKodeverk = ehelseUrl + '/kodeverk-og-terminologi'
const helsedirUrl = 'https://www.helsedirektoratet.no'

const aboutLab = '/Laboratoriekodeverk'
const aboutNorpat = '/Norsk-patologikodeverk'
const aboutSnomedct = '/SNOMED-CT'
const aboutIcd = '/ICD-10-og-ICD-11'
const aboutIcpc = '/ICPC-2'
const aboutPhbu =
	'/Multiaksial-klassifikasjon-i-psykisk-helsevern-for-barn-og-unge-(PHBU)'
const aboutNkpk = '/Norsk-klinisk-prosedyrekodeverk-(NKPK)'
const aboutKis = '/kodeverk-i-e-helsestandarder'

const reportErrorLab = 'lab@helsedir.no'
const reportErrorEmail = 'kodehjelp@helsedir.no'
const reportErrorAdm = 'kodehjelp@helsedir.no'
const koteUrl = 'https://kote.helsedirektoratet.no'
const terminologiString = '/terminologi'
const kliniskeString = '/kliniske'

const snomedctBrowser = 'https://snowstorm.terminologi.helsedirektoratet.no'

const blueBookIcdAttachment =
	'/_/attachment/inline/7f5b9c53-8571-4df5-a9af-4de42ece2a8a:6875a2309af7447a26d9731a6e7ff2cba814c14e/Komplett%20Blåbok%20IDC-10%20Psykiske%20lidelser%20og%20atferdsforstyrrelser%20%282%29.pdf'
const codingGuideIcdAndNkpkAttachment =
	'/_/attachment/inline/ffe2dcf6-6f26-4cb8-953b-899c04d7773e:47264841609b87504bb5c7b36c9ba29769a736b7/Kodeveiledning%202024.pdf'
const codingGuideNkpkAttachment =
	'/_/attachment/inline/ffe2dcf6-6f26-4cb8-953b-899c04d7773e:47264841609b87504bb5c7b36c9ba29769a736b7/Kodeveiledning%202024.pdf'

/* Temporary download files until the api works well with clinical codesystems */
const downloadExcelIcdTemp =
	'/ICD-10-og-ICD-11/_/attachment/inline/7fbef2ed-2cc9-40ad-8d05-2a5244424c68:5a12871a6fb1d852fbfcc552ca4d05c9012bc562/Kodeliste%20ICD-10%202024%20-%20oppdatert%20juli%202024.xlsx'
const downloadExcelIcpcTemp =
	'/ICPC-2/_/attachment/inline/d527d279-39c0-4eb7-8e54-9a916929e613:0ccfa8bdcced2d3c27ade94aac32d5b8a2e2eb22/Fil%201%202024%20-%20ICPC-2%20koderegister%20med%20utvidet%20termsett%20(flere%20linjer%20per%20kode).xlsx'
const downloadExcelNkpkTemp =
	'/Norsk-klinisk-prosedyrekodeverk-(NKPK)/_/attachment/inline/0607d594-aa5f-407b-bbcc-e0dade66100f:bdc4f0658a3c0a1b19a0f1809f1f9643ca0907f8/Alle%20prosedyrekoder%202024.xlsx'
const downloadExcelPhbuTemp =
	'/Multiaksial-klassifikasjon-i-psykisk-helsevern-for-barn-og-unge-(PHBU)/_/attachment/inline/ea6dd48f-d4d8-4363-8e00-7185f7837902:a0ecd3acba8c00f5249400c9c477b20f61aeb521/Kodeliste%20PHBU%202024.xlsx'
const downloadCsvNlkTemp =
	'/Laboratoriekodeverk/_/attachment/inline/18b2c07f-2662-4762-9561-0465530cba19:c3ff952e1c91917e25235aff9448cdbfdd31b82c/Norsk%20laboratoriekodeverk%207280.69.csv'

const ehelseFeedbackForm =
	'https://forms.office.com/Pages/ResponsePage.aspx?id=XL2haw911kqrow-VWFvCH_vvJjJGPaVFqLMA_mOzyM5URE81V1oyM1VMQkNDOUxXUUlKUFZVUFIxVC4u'

const externalUrls = {
	ehelseUrl,
	helsedirUrl,
	ehelseKodeverk,

	/* about */
	aboutLab: ehelseKodeverk + aboutLab,
	aboutNorpat: ehelseKodeverk + aboutNorpat,
	aboutSnomedct: ehelseKodeverk + aboutSnomedct,
	aboutIcd: ehelseKodeverk + aboutIcd,
	aboutIcpc: ehelseKodeverk + aboutIcpc,
	aboutPhbu: ehelseKodeverk + aboutPhbu,
	aboutNkpk: ehelseKodeverk + aboutNkpk,
	aboutKis: ehelseKodeverk + aboutKis,

	/* report error */
	reportErrorClinical: koteUrl + kliniskeString,
	reportErrorTerminology: koteUrl + terminologiString,
	reportErrorLab,
	reportErrorEmail,
	reportErrorAdm,
	ehelseFeedbackForm,

	/* temp download file urls */
	downloadExcelIcdTemp: ehelseKodeverk + downloadExcelIcdTemp,
	downloadExcelIcpcTemp: ehelseKodeverk + downloadExcelIcpcTemp,
	downloadExcelNkpkTemp: ehelseKodeverk + downloadExcelNkpkTemp,
	downloadExcelPhbuTemp: ehelseKodeverk + downloadExcelPhbuTemp,
	downloadCsvNlkTemp: ehelseKodeverk + downloadCsvNlkTemp,

	/* blåbok og kodeveilder */
	blueBookIcd: ehelseKodeverk + aboutIcd + blueBookIcdAttachment,
	codingGuideIcd: ehelseKodeverk + aboutIcd + codingGuideIcdAndNkpkAttachment,
	codingGuideNkpk: ehelseKodeverk + aboutIcd + codingGuideIcdAndNkpkAttachment,

	snomedctBrowser,
}

export default externalUrls
