import { Accordion, Card } from 'react-bootstrap'
import { AccordionItemProps } from './interface'
import CustomAccordionToggle from '../../../base/CustomAccordionToggle'

const AccordionItem = ({
	eventKey,
	body,
	headerTitle,
	onClick,
}: AccordionItemProps) => {
	return (
		<Card onClick={onClick}>
			<Card.Header>
				<CustomAccordionToggle eventKey={eventKey}>
					{headerTitle}
				</CustomAccordionToggle>
			</Card.Header>
			<Accordion.Collapse eventKey={eventKey}>
				<Card.Body>{body}</Card.Body>
			</Accordion.Collapse>
		</Card>
	)
}

export default AccordionItem
