import type { FacetViewProps } from '@elastic/react-search-ui-views'
import { appendClassName } from 'utils'
import { useEffect, useState } from 'react'
import { Checkbox, FilterWrapper } from 'components/common/elastic/style'

const ReverseBooleanFacet = ({
	className,
	label,
	options,
	onRemove,
	onSelect,
	values,
}: FacetViewProps) => {
	/* Denne er bakvendt, til fordel for "vis inaktive koder".
			Fordi, inaktive koder har en false verdi, og aktive koder har en true verdi.
			For å vise inaktive koder, må vi derfor velge false.
			Som går i mot de andre, der man må velge true verdien.
	*/
	const trueOptions = options.find((option) => option.value === 'false')
	const isSelected = values.includes('false')

	const [isDisabled, setIsDisabled] = useState<boolean>(false)

	const handleToggle = () => {
		if (isSelected) {
			onRemove('false')
		} else {
			onSelect(['false', 'true'])
		}
	}

	useEffect(() => {
		if (!trueOptions) {
			setIsDisabled(true)
		} else {
			setIsDisabled(false)
		}
	}, [trueOptions, isDisabled])

	return (
		<FilterWrapper className={appendClassName(className)}>
			<Checkbox
				type='checkbox'
				label={label}
				id={`facet-${label}`}
				name={`facet-${label}`}
				disabled={isDisabled}
				checked={isSelected}
				onChange={handleToggle}
				style={{ fontWeight: isSelected ? 'bold' : 'normal' }}
			/>
		</FilterWrapper>
	)
}

export default ReverseBooleanFacet
