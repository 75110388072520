import type { DefaultTheme } from 'styled-components/dist/types'

export enum COLORS {
	BLUE = 'blue',
	RED = 'red',
	YELLOW = 'yellow',
	GREEN = 'green',
	BEIGE = 'beige',
}

export interface IProps {
	theme?: DefaultTheme
	color?: string
}

export interface TagProps {
	text: string
	color: string
}
