import { useTranslation } from 'react-i18next'
import { OptionButtonProps } from './interface'
import Button from 'components/common/elements/Button'
import { styled } from 'styled-components'

const TextWrapper = styled.div`
	.extra-text {
		font-size: 0.9rem;
		color: ${({ theme }) => theme.colours.primary.blue};
		font-weight: 600;
	}
`

const OptionButton: React.FC<OptionButtonProps> = ({
	icon,
	size = 'sm',
	text,
	onClick,
	hasExtraText,
	extraText,
}) => {
	const { t } = useTranslation()
	return (
		<Button
			icon={icon}
			size={size}
			className='ps-2 text-start align-items-start'
			onClick={onClick}
			iconClassName='pt-1'
		>
			<TextWrapper>
				{t(text)}
				{hasExtraText && <div className='extra-text'>{extraText}</div>}
			</TextWrapper>
		</Button>
	)
}

export default OptionButton
