export const breakpointsNumbers = [576, 768, 992, 1200, 1400, 1600, 1800]

export type BreakpointNames =
	| 'sm'
	| 'md'
	| 'lg'
	| 'xl'
	| 'xxl'
	| 'xxxl'
	| 'huge'
type BreakpointsObject = { [breakpoint in BreakpointNames]: number }
type BreakpointArg = BreakpointNames | number

export const breakpoints: BreakpointsObject = {
	sm: breakpointsNumbers[0],
	md: breakpointsNumbers[1],
	lg: breakpointsNumbers[2],
	xl: breakpointsNumbers[3],
	xxl: breakpointsNumbers[4],
	xxxl: breakpointsNumbers[5],
	huge: breakpointsNumbers[6],
}

const getBreakpointWidth = (breakpoint: BreakpointArg): number =>
	typeof breakpoint === 'number' ? breakpoint : breakpoints[breakpoint]

export const over = (min: BreakpointArg): string =>
	`@media (min-width: ${getBreakpointWidth(min)}px)`

export const under = (max: BreakpointArg): string =>
	`@media (max-width: ${getBreakpointWidth(max)}px)`

export const between = (min: BreakpointArg, max: BreakpointArg): string =>
	`@media (min-width:${getBreakpointWidth(
		min
	)}px) and (max-width:${getBreakpointWidth(max)}px)`
