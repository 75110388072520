import type { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { LinkProps } from './interface'
import { LinkWrapper } from './style'

const Link = ({
	text,
	textElement,
	href,
	target,
	iconLeft,
	iconRight,
	icon,
	fontSize,
	isBlue = true,
	hasUnderline = false,
	onClick,
	className,
}: LinkProps) => {
	return (
		<LinkWrapper
			to={href}
			target={target}
			$fontSize={fontSize}
			$isBlue={isBlue}
			$hasUnderline={hasUnderline}
			onClick={onClick}
			className={className}
		>
			{iconLeft && <FontAwesomeIcon icon={icon as IconProp} size='sm' />}
			{text && <span className='hover-effect'>{text}</span>}
			{textElement && textElement}
			{iconRight && <FontAwesomeIcon icon={icon as IconProp} size='sm' />}
		</LinkWrapper>
	)
}

export default Link
