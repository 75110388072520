import { Col } from 'react-bootstrap'
import { styled } from 'styled-components'

export const ContentHeader = styled(Col)`
	font-weight: bold;
	border-bottom: 1px solid ${(props) => props.theme.colours.primary.blue};
	margin-top: 0.5rem;
`

export const Content = styled(Col)`
	min-height: 40px;
	background-color: white;
	padding: 0.5rem;
`
