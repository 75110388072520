import { NLKSearch } from 'components/search'

const NLKPage = () => {
	return (
		<>
			<NLKSearch />
		</>
	)
}

export default NLKPage
