export const searchQuery = {
	disjunctiveFacets: ['member_of_refsets'],
	disjunctiveFacetsAnalyticsTags: ['ignore'],
	facets: {
		member_of_refsets: {
			type: 'value',
		},
	},
	search_fields: {
		name_norwegian: {
			weight: 3,
		},
		code: {
			weight: 1,
		},
		subterm: {
			weight: 2,
		},
		name_english: {
			weight: 1,
		},
	},
	result_fields: {
		id: {
			raw: {},
		},
		name_norwegian: {
			snippet: {
				size: 200,
				fallback: true,
			},
			raw: {},
		},
		name_english: {
			snippet: {
				size: 200,
				fallback: true,
			},
			raw: {},
		},
		code: {
			snippet: {
				size: 200,
				fallback: true,
			},
			raw: {},
		},
		subterm: {
			snippet: {
				size: 200,
				fallback: true,
			},
			raw: {},
		},
		code_system: {
			snippet: {
				size: 200,
				fallback: true,
			},
			raw: {},
		},
		member_of_refsets: {
			snippet: {
				size: 200,
				fallback: true,
			},
			raw: {},
		},
	},
}
