const highlightSearchTerm = (text: string, searchTerm: string) => {
	// If searchTerm is empty or undefined, return the original text
	if (!searchTerm) {
		return text
	}

	// Escape special characters in the search term
	const escapedSearchTerm = searchTerm.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')

	// Create a regular expression from the escaped search term
	const regex = new RegExp(`(${escapedSearchTerm})(?![^<]*>|[^<>]*</)`, 'gi')

	// Replace all instances of the search term with the search term wrapped in <em> tags
	const highlightedText = text.replace(regex, '<em>$1</em>')

	return highlightedText
}

export default highlightSearchTerm
