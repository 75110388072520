const formatDate = (date: string) => {
	const createDate = new Date(date)
	const newDate = createDate.toLocaleDateString('no-NO', {
		year: 'numeric',
		month: 'long',
		day: 'numeric',
	})
	if (newDate === 'Invalid Date') return ''
	return newDate
}

export default formatDate
