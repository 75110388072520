import React, { createContext, useContext } from 'react'
import { useStore, StoreState, StoreActions } from './useStore'

interface StoreContextValue {
	state: StoreState
	actions: StoreActions
}

interface StoreProviderProps {
	children: React.ReactNode
}

const StoreContext = createContext<StoreContextValue | undefined>(undefined)

export const StoreProvider: React.FC<StoreProviderProps> = ({ children }) => {
	const [state, actions] = useStore()

	const value: StoreContextValue = {
		state,
		actions,
	}

	return <StoreContext.Provider value={value}>{children}</StoreContext.Provider>
}

export const useStoreContext = (): StoreContextValue => {
	const context = useContext(StoreContext)
	if (!context) {
		throw new Error('useStoreContext must be used within a StoreProvider')
	}
	return context
}
