import { css, styled } from 'styled-components'
import { over } from 'utils'

const baseStyles = css`
	font-weight: 400;
	margin: 0;
	padding: 0 !important;
	line-height: 130%;
	word-break: break-word;
`

export const StyledH1 = styled.h1`
	${baseStyles}
	font-size: 28px;
	font-family: ${(props) => props.theme.fonts.headings};
	letter-spacing: ${(props) => props.theme.letterSpacing.headings};
	${over('md')} {
		font-size: 32px;
	}
`

export const StyledH2 = styled.h2`
	${baseStyles}
	font-size: 28px;
	font-family: ${(props) => props.theme.fonts.headings};
	letter-spacing: ${(props) => props.theme.letterSpacing.headings};
`

export const StyledH3 = styled.h3`
	${baseStyles}
	font-size: 24px;
	font-family: ${(props) => props.theme.fonts.senserif};
	letter-spacing: ${(props) => props.theme.letterSpacing.normal};
`

export const StyledH4 = styled.h4`
	${baseStyles}
	font-size: 20px;
	font-family: ${(props) => props.theme.fonts.senserif};
	letter-spacing: ${(props) => props.theme.letterSpacing.normal};
`

export const StyledH5 = styled.h5`
	${baseStyles}
	font-size: 18px;
	font-family: ${(props) => props.theme.fonts.senserif};
	letter-spacing: ${(props) => props.theme.letterSpacing.normal};
`
