export const autocompleteQuery = {
	results: {
		resultsPerPage: 5,
		result_fields: {
			name_norwegian: {
				raw: {},
			},
			code: {
				raw: {},
			},
		},
	},
	suggestions: {
		types: {
			documents: {
				fields: ['name_norwegian', 'code', 'subterm'],
			},
		},
		size: 5,
	},
}
