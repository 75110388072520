import codesystem from 'utils/constants/codesystemsEndpointName'
import codesystems from 'utils/constants/codesystems'

/* Is used to get the correct headline for all search pages */

const codeSystemMap: Record<string, string> = {
	[codesystem.icd10]: codesystems.icd10,
	[codesystem.icpc2]: codesystems.icpc2,
	[codesystem.icpc2b]: codesystems.icpc2b,
	[codesystem.nkpk]: codesystems.nkpk,
	[codesystem.phbu]: codesystems.phbu,
	[codesystem.norpat]: codesystems.norpat,
	[codesystem.apat]: codesystems.apat,
	[codesystem.nlk]: codesystems.nlk,
	[codesystem.adm]: 'kodeverk i standarder',
	[codesystem.provemateriale]: 'prøvemateriale',
	[codesystem.anatomisk]: 'anatomisk lokalisasjon',
	[codesystem.snomedct]: codesystems.snomedct,
	[codesystem.tekstligeresultatverdier]: 'tekstlige resultatverdier',
	[codesystem.undersokelsesmetode]: 'undersøkelsesmetode',
}

const getCodeSystemName = (value: string) => {
	return codeSystemMap[value]
}

export default getCodeSystemName
