const apiEndpointDev = process.env.REACT_APP_FAT_URL as string
const apiEndpointProd = process.env.REACT_APP_FAT_URL as string
const apiCodesystem = '/api/code-systems'
const apiSnomed = '/api/snomed'
const apiAdm = '/adm/codelist'
const apiAdmCollections = '/adm/collections'
const excelEndpoint = '/download'

const endpoints = {
	apiUrlDev: apiEndpointDev,
	apiUrlProd: apiEndpointProd,
	apiEndpointDev: apiEndpointDev + apiCodesystem,
	apiEndpointProd: apiEndpointProd + apiCodesystem,
	apiAdmEndpointDev: apiEndpointDev + apiCodesystem + apiAdm,
	apiAdmEndpointProd: apiEndpointProd + apiCodesystem + apiAdm,
	apiAdmCollectionsEndpointDev:
		apiEndpointDev + apiCodesystem + apiAdmCollections,
	apiAdmCollectionsEndpointProd:
		apiEndpointProd + apiCodesystem + apiAdmCollections,
	apiSnomedEndpointDev: apiEndpointDev + apiSnomed,
	apiCodesystem,
	excelEndpoint,
}

export default endpoints
