const getStatusText = (value: string) => {
	switch (value) {
		case 'Til utbredelse eller er i bruk':
			return 'Aktiv'
		case 'Foreldet og erstattet av annet kodeverk':
		case 'Utgått':
			return 'Inaktiv'
		default:
			return value
	}
}

export default getStatusText
