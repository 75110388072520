import type { DefaultTheme } from 'styled-components'

const getButtonFontSize = (theme: DefaultTheme, size?: string) => {
	switch (size) {
		case 'xs':
			return '14px'
		case 'sm':
			return '16px'
		case 'md':
			return '18px'
		case 'lg':
			return '20px'
		case 'xl':
			return '22px'
		case 'xxl':
			return '24px'
	}
}
export default getButtonFontSize
