import { Col } from 'react-bootstrap'
import { styled } from 'styled-components'
import { over } from 'utils'

const ScrollWrapper = styled(Col)<{
	$isSmallLabCodework?: boolean
	$scrollHeight?: string
	$isAdm?: boolean
}>`
	position: relative;
	overflow-x: ${({ $isAdm }) => ($isAdm ? 'auto' : 'unset')};
	width: 100%;
	${over('lg')} {
		overflow-x: unset;
	}
`

export default ScrollWrapper
