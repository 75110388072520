import GeneralSearch from 'components/search/buckets/GeneralSearch'

const GeneralSearchResults = () => {
	return (
		<>
			<GeneralSearch />
		</>
	)
}

export default GeneralSearchResults
