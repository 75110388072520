import { styled } from 'styled-components'

export const FooterWrapper = styled.footer`
	background-color: ${(props) => props.theme.colours.primary.blue};
	color: ${(props) => props.theme.colours.primary.white};
	padding: 4rem 0;

	margin-top: 4rem;

	.footer-text {
		font-size: 22px !important;
		line-height: 150%;
	}
	ul {
		list-style: none;
		padding: 0;
	}
	li {
		padding-bottom: 0.5rem;
	}

	img {
		object-fit: contain;
	}
`

export const FooterLink = styled.a`
	display: inline-block;
	position: relative;
	color: ${(props) => props.theme.colours.primary.white};
	text-decoration: none;
	&:hover {
		color: ${(props) => props.theme.colours.primary.white};
	}
	&::after {
		content: '';
		position: absolute;
		width: 100%;
		transform: scaleX(0);
		height: 2px;
		bottom: 0;
		left: 0;
		background-color: ${(props) => props.theme.colours.primary.blue};
		transform-origin: bottom right;
		transition: transform 0.25s ease-out;
	}
	&:hover::after {
		transform: scaleX(1);
		transform-origin: bottom left;
	}
`
