import { Accordion } from 'react-bootstrap'
import { styled } from 'styled-components'

export const AccordionWrapper = styled(Accordion)`
	border: none;
	.card {
		border: none;
		background-color: ${(props) =>
			props.theme.colours.secondary.lightYellow} !important;
		border: 1px solid #f2c94c;
		.card-header {
			background: inherit;
			border-radius: inherit;
			padding: 0;
			display: flex;
			justify-content: center;
			border: none;
			font-size: 16px;
			font-weight: 600;
		}
		.card-body {
			text-align: center;
			padding: 0rem 0;
			padding-bottom: 0.7rem;
		}
	}
	svg {
		color: ${(props) => props.theme.colours.primary.blue};
	}
`
