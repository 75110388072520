import type { CSSProperties, ReactNode } from 'react'

export interface HeadingProps {
	id: string
	variant: string
	children: ReactNode
	className?: string
	style?: CSSProperties
	onClick?: () => void
}

export enum HEADING {
	H1 = 'h1',
	H2 = 'h2',
	H3 = 'h3',
	H4 = 'h4',
	H5 = 'h5',
}
