const getToastText = (text: string) => {
	switch (text) {
		case 'successAddItem':
			return 'Kodeverk lagt til i nedlastning'
		case 'removed':
			return 'Kodeverk fjernet fra nedlastning'
		case 'errorAlreadyExists':
			return 'Kodeverk finnes allerede i nedlastning'
		case 'countError':
			return 'Du kan ikke legge til flere enn 200 kodeverk'
		case 'sucessDownloadAll':
			return 'Kodeverkene er lastet ned'
		case 'removeAll':
			return 'Alle kodeverk er fjernet'
		default:
			return ''
	}
}

export default getToastText
