import { Row } from 'react-bootstrap'
import parse, {
	type HTMLReactParserOptions,
	type Element,
} from 'html-react-parser'
import { useStoreContext } from 'context/StoreProvider'
import { formatDate } from 'utils'
import { replaceLink } from 'components/search/results/utils'

interface ParseContentProps {
	content: string
	headline?: string
	isDate?: boolean
}

const ParseContent = ({ content, headline, isDate }: ParseContentProps) => {
	const { state } = useStoreContext()
	const { codeSystem } = state
	const options: HTMLReactParserOptions = {
		replace: (domNode) => replaceLink(domNode as Element, codeSystem as string),
	}
	return (
		<Row className={`${headline && 'flex-column'} p-0`}>
			{headline && <section className='fw-bold p-0'>{headline}</section>}

			{isDate ? (
				<section className='p-0'>{parse(formatDate(content))}</section>
			) : (
				<section className='p-0'>{parse(content, options)}</section>
			)}
		</Row>
	)
}

export default ParseContent
