import type { DefaultTheme } from 'styled-components'
import { COLORS } from '../interface'

const getButtonColor = (theme: DefaultTheme, color?: string) => {
	switch (color) {
		case COLORS.WHITE:
			return theme.colours.primary.white
		case COLORS.BLUE:
			return theme.colours.primary.blue
		case COLORS.GREY:
			return theme.colours.primary.greyShade
		case COLORS.BLACK:
		default:
			return theme.colours.primary.black
	}
}

export default getButtonColor
