import { css, styled } from 'styled-components'
import type { DefaultTheme } from 'styled-components'
import {
	getButtonColor,
	getButtonBorderSize,
	getButtonFontSize,
	getButtonPadding,
} from './utils'
import { IProps } from './interface'

const buttonVariants = (
	theme: DefaultTheme,
	variant = 'primary',
	size?: string,
	color?: string,
	borderSize?: string,
	textColor?: string
) =>
	({
		primary: css`
			background-color: transparent;
			color: ${() => getButtonColor(theme, color)};
			border: none;
			padding: ${() => getButtonPadding(size)};
			font-size: ${() => getButtonFontSize(theme, size)};
			&:hover,
			&:active,
			&:focus {
				box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.1); // for lysere hover
				background-color: transparent !important;
				color: ${() => getButtonColor(theme, color)};
			}
		`,
		secondary: css`
			background: white;
			border: 2px solid ${theme.colours.primary.blue};
			color: ${theme.colours.primary.black};
			padding: ${() => getButtonPadding(size)};
			font-size: ${() => getButtonFontSize(theme, size)};
			border-radius: 500px;
			&:hover,
			&:active {
				background-color: ${theme.colours.primary.blue} !important;
				color: ${theme.colours.primary.white} !important;
				text-decoration: none;
				svg {
					color: ${theme.colours.primary.white} !important;
				}
			}
		`,
		tertiary: css`
			background: white;
			border-width: ${() => getButtonBorderSize(borderSize)};
			border-style: solid;
			border-color: ${() => getButtonColor(theme, color)};
			color: ${() => getButtonColor(theme, textColor)};
			padding: ${() => getButtonPadding(size)};
			font-size: ${() => getButtonFontSize(theme, size)};
			border-radius: 500px;
			&:hover {
				background-color: ${theme.colours.primary.blue} !important;
				color: ${theme.colours.primary.white} !important;
				text-decoration: none;
			}
			&:active {
				background-color: transparent;
			}
		`,
		blue: css`
			background: ${theme.colours.primary.blue};
			border: 2px solid ${theme.colours.primary.blue};
			color: ${theme.colours.primary.white};
			padding: ${size === 'sm' ? '0.3rem 0.8rem' : '0.6rem 1.2rem'};
			font-size: ${size === 'sm' ? '16px' : '20px'};
			border-radius: 500px;
			&:hover,
			&:active {
				background-color: ${theme.colours.primary.white} !important;
				color: ${theme.colours.primary.blue} !important;
				text-decoration: none;
			}
		`,
		select: css`
			background: transparent;
			border: 1px solid ${theme.colours.primary.greyShade};
			color: ${theme.colours.primary.black};
			padding: ${size === 'sm' ? '0.3rem 0.8rem' : '0.6rem 1.2rem'};
			font-size: ${size === 'sm' ? '16px' : '20px'};
			border-radius: 3px;
			&:hover,
			&:active {
				background-color: ${theme.colours.primary.blueLighter} !important;
				color: ${theme.colours.primary.black} !important;
				text-decoration: none;
			}
		`,
	})[variant]

export const ButtonWrapper = styled.button<IProps>`
	cursor: pointer;
	display: flex;
	align-items: center;
	gap: 0.3rem;
	flex-direction: ${(props) => (props.$iconright ? 'row-reverse' : 'row')};
	position: relative;
	transition: all 0.2s ease-in-out;
	&:hover {
		text-decoration: underline;
	}

	.icon-container {
		display: flex;
		align-content: center;
		align-items: center;
		min-width: 24px;
		min-height: 24px;
	}

	svg {
		min-width: 16px;
		min-height: 16px;
	}

	${(props) =>
		props.disabled &&
		css`
			opacity: 0.5;
			border: none !important;
			cursor: default;
			&:hover,
			&:active {
				box-shadow: inset 0 0 100px 100px transparent !important;
				font-style: normal;
			}
		`}

	${({ theme, $variant, $size, color, $borderSize, $textColor }) =>
		buttonVariants(theme, $variant, $size, color, $borderSize, $textColor)}
`
