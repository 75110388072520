const getActiveStatus = (value: string) => {
	switch (value) {
		case 'true':
			return 'green'
		case 'false':
			return 'red'
		default:
			return 'yellow'
	}
}

export default getActiveStatus
