import type { IconProp } from '@fortawesome/fontawesome-svg-core'
import {
	CSSProperties,
	KeyboardEventHandler,
	MouseEventHandler,
	ReactNode,
} from 'react'
import type { To } from 'react-router-dom'

export interface ButtonProps {
	type?: 'button' | 'reset' | 'submit' | undefined
	className?: string
	id?: string
	onClick?: MouseEventHandler<HTMLButtonElement> | undefined
	onKeyDown?: KeyboardEventHandler<HTMLButtonElement> | undefined
	size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | undefined
	icon?: IconProp
	children: ReactNode | string
	color?: string
	borderSize?: '1' | '2' | '3' | '4' | undefined
	textColor?: string
	iconSize?: 'sm' | 'lg'
	variant?:
		| 'primary'
		| 'secondary'
		| 'tertiary'
		| 'disabled'
		| 'select'
		| 'blue'
		| undefined
	style?: CSSProperties
	role?: string
	ariaLabel?: string
	name?: string
	disabled?: boolean
	iconright?: boolean
	iconClassName?: string
	link?: To
	innerRef?:
		| React.RefObject<HTMLButtonElement>
		| ((node?: Element | null) => void)
		| undefined
}

export interface IProps {
	$variant?: string
	$size?: string
	$color?: string
	$disabled?: boolean
	$iconright?: boolean
	$borderSize?: string
	$textColor?: string
}

export enum COLORS {
	BLACK = 'black',
	PURPLE = 'purple',
	WHITE = 'white',
	BLUE = 'blue',
	GREY = 'grey',
}
