import { Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { ThreeDots } from 'react-loader-spinner'

interface LoadingProps {
	wrapperClass?: string
}

const Loading = ({ wrapperClass }: LoadingProps) => {
	const { t } = useTranslation()
	return (
		<Row className='flex-column align-content-center justify-content-center gap-3'>
			<Col xs='auto' className='d-flex justify-content-center'>
				<ThreeDots
					height='50'
					width='50'
					radius='9'
					color='#0069E8'
					ariaLabel='three-dots-loading'
					visible={true}
					wrapperClass={`${wrapperClass}`}
				/>
			</Col>
			<Col xs='auto'>{t('search.isLoading')}</Col>
		</Row>
	)
}

export default Loading
