import { Col, Row } from 'react-bootstrap'
import { TermsType } from '../../interface'
import { Fragment } from 'react'
import { Heading } from 'components/common'
import { useTranslation } from 'react-i18next'
import { isEnglish } from '../../utils'
import { capitalizeFirstLetter } from 'utils'

interface TermProps {
	terms: TermsType[]
}

const Term = ({ terms }: TermProps) => {
	const { t } = useTranslation()

	return (
		<Row className='gap-3 flex-column flex-lg-row p-0'>
			<Heading variant='h3' className='fs-4' id='snomed-terms-heading'>
				{t('snomedctPage.terms.title')}
			</Heading>
			<Row className='d-flex gap-3 flex-column flex-md-row p-0'>
				{terms.map((term) => {
					const isNorwegian = term.language.includes('nb-NO')
					if (isEnglish(term)) return null
					return (
						<Fragment key={term.language}>
							<Col className='term' key={term.language}>
								<Heading
									variant='h4'
									className='fs-5 fw-bold'
									id='snomed-term-heading'
								>
									{isNorwegian
										? t('snomedctPage.terms.nb-NO')
										: t('snomedctPage.terms.nn-NO')}
								</Heading>

								<div className='d-flex flex-column gap-2'>
									<div className='d-flex flex-column'>
										<span className='fw-bold'>
											{t('snomedctPage.terms.preferredTerm')}
										</span>
										<span>{term.preferredTerm}</span>
									</div>

									{term.synonyms && term.synonyms.length > 0 && (
										<div className='d-flex flex-column'>
											<span className='fw-bold'>
												{t('snomedctPage.terms.synonyms')}
											</span>
											<ul>
												{term.synonyms.map((synonym) => (
													<li key={synonym}>{synonym}</li>
												))}
											</ul>
										</div>
									)}

									{Array.isArray(term.langReferenceSets) &&
										term.langReferenceSets.map((langReferenceSet) => (
											<Fragment key={langReferenceSet.refsetId}>
												<Heading
													variant='h5'
													className='fs-6 mt-3 fw-bold'
													id='language-refset-heading'
												>
													{capitalizeFirstLetter(langReferenceSet.refsetName)}
												</Heading>
												<div className='d-flex flex-column'>
													<span className='fw-bold'>
														{t('snomedctPage.terms.preferredTerm')}
													</span>
													<span>{langReferenceSet.preferredTerm}</span>
												</div>
												{langReferenceSet.synonyms &&
													langReferenceSet.synonyms.length > 0 && (
														<div className='d-flex flex-column'>
															<span className='fw-bold'>
																{t('snomedctPage.terms.synonyms')}
															</span>
															<ul>
																{langReferenceSet.synonyms.map((synonym) => (
																	<li key={synonym}>{synonym}</li>
																))}
															</ul>
														</div>
													)}
											</Fragment>
										))}
								</div>
							</Col>
						</Fragment>
					)
				})}
			</Row>
		</Row>
	)
}

export default Term
