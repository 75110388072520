import type { FilterType, FilterValue } from '@elastic/search-ui'
import { connector } from '../../ElasticSearch/config/settings'
import { resultFields } from '../../SearchWithFilter/config/settings'

export const nlkConfig = {
	apiConnector: connector,
	hasA11yNotifications: false,
	alwaysSearchOnInitialLoad: true,
	searchQuery: {
		search_fields: {
			name_norwegian: {},
			code: {},
			subject_area: {},
			component: {},
			short_definition: {},
			component_specification: {},
			property_type_specification: {},
		},
		filters: [
			{
				field: 'code_system',
				values: ['NLK' as FilterValue],
				type: 'any' as FilterType,
			},
			{
				field: 'active',
				values: ['true' as FilterValue],
				type: 'any' as FilterType,
			},
		],
		disjunctiveFacets: [
			'subject_area',
			'active',
			'system',
			'group',
			'component',
			'unit',
			'property_type',
		],
		facets: {
			active: {
				type: 'value',
				size: 100,
			},
			subject_area: {
				type: 'value',
				size: 200,
			},
			system: {
				type: 'value',
				size: 100,
			},
			group: {
				type: 'value',
				size: 100,
			},
			component: {
				type: 'value',
				size: 100,
			},
			unit: {
				type: 'value',
				size: 100,
			},
			property_type: {
				type: 'value',
				size: 100,
			},
		},
		result_fields: resultFields,
	},
}
