import { styled } from 'styled-components'
import { Link } from 'react-router-dom'

export const LinkWrapper = styled(Link)<{
	$fontSize?: string
	$isBlue: boolean
	$hasUnderline: boolean
}>`
	position: relative;
	color: ${(props) =>
		props.$isBlue
			? props.theme.colours.primary.blue
			: props.theme.colours.primary.white};
	display: flex;
	align-items: center;
	text-decoration: ${(props) => (props.$hasUnderline ? 'underline' : 'none')};
	width: fit-content;
	gap: 0.5rem;
	font-size: ${(props) => props.$fontSize ?? props.theme.fontSizes.bodyText};
	&:hover {
		color: ${(props) =>
			props.$isBlue
				? props.theme.colours.primary.blue
				: props.theme.colours.primary.white};
	}
	.hover-effect {
		&::after {
			content: '';
			position: absolute;
			width: 100%;
			transform: scaleX(0);
			height: 2px;
			bottom: 0;
			left: 0;
			background-color: ${(props) =>
				props.$isBlue
					? props.theme.colours.primary.blue
					: props.theme.colours.primary.white};
			transform-origin: bottom right;
			transition: transform 0.25s ease-out;
		}
		&:hover::after {
			transform: scaleX(1);
			transform-origin: bottom left;
		}
	}
`
