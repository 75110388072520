import type { DefaultTheme } from 'styled-components/dist/types'
import { COLORS } from '../interface'

const getColor = (theme: DefaultTheme, color?: string) => {
	switch (color) {
		case COLORS.BLUE:
			return theme.colours.secondary.blueSignal
		case COLORS.RED:
			return theme.colours.secondary.redSignal
		case COLORS.YELLOW:
			return theme.colours.secondary.lightYellow
		case COLORS.GREEN:
			return theme.colours.secondary.greenSignal
		case COLORS.BEIGE:
			return theme.colours.secondary.lightBeige
		default:
			return theme.colours.secondary.lightYellow
	}
}

export default getColor
