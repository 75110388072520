import { SearchProvider, WithSearch } from '@elastic/react-search-ui'
import '@elastic/react-search-ui-views/lib/styles/styles.css'
import { useTranslation } from 'react-i18next'
import { ElasticWrapper } from 'components/common'
import { useStoreContext } from 'context/StoreProvider'
import GeneralFilter from './common/GeneralFilter'
import BodyResults from './common/BodyResults'
import { resultsPerPage } from 'utils'
import { SearchPageLayout } from '../common'
import { generalConfig } from './config/generalConfig'

const GeneralSearch = () => {
	const { t } = useTranslation()

	const { state } = useStoreContext()

	const { code } = state

	return (
		<SearchProvider
			config={{
				initialState: {
					searchTerm: code ?? '',
					resultsPerPage: resultsPerPage,
				},
				...generalConfig,
			}}
		>
			<WithSearch
				mapContextToProps={({
					searchTerm,
					isFetching,
					resultSearchTerm,
					isLoading,
					results,
				}) => ({
					searchTerm,
					isFetching,
					resultSearchTerm,
					isLoading,
					results,
				})}
			>
				{({ searchTerm, isFetching, isLoading, results }) => {
					return (
						<ElasticWrapper className='d-flex flex-column gap-3'>
							<SearchPageLayout
								headingLabel={t('general.searchTitle', {
									term: t('frontpage.title'),
								})}
								results={results}
								searchTerm={searchTerm}
								isFetching={isFetching}
								isLoading={isLoading}
								sidebarContent={<GeneralFilter />}
								bodyResults={<BodyResults />}
							/>
						</ElasticWrapper>
					)
				}}
			</WithSearch>
		</SearchProvider>
	)
}

export default GeneralSearch
