const fetchApi = async (url: string, error: void) => {
	try {
		const res = await fetch(url)

		if (!res.ok && res.status !== 404) {
			return error
		}
		if (!res.ok) {
			// If the server responds with a 404 error, fetch the root instead
			const rootResponse = await fetch(url)
			return await rootResponse.json()
		} else {
			return await res.json()
		}
	} catch (err) {
		throw err
	}
}

export default fetchApi
