import { faFaceFrown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Col, Row } from 'react-bootstrap'
import { Trans, useTranslation } from 'react-i18next'
import { ErrorMessageProps } from './interface'
import Heading from '../Heading'
import { Link } from 'react-router-dom'
import { externalUrls } from 'utils'

const ErrorMessage = ({
	serverError,
	noResults,
	searchTerm,
	className,
	errorResponse,
}: ErrorMessageProps) => {
	const { t } = useTranslation()
	const getErrorMessage = () => {
		switch (errorResponse) {
			case '404':
				return t('search.error.404')
			case '500':
				return t('search.error.500')
			default:
				return t('search.error.general')
		}
	}
	return (
		<Row
			className={`flex-column align-content-center justify-content-center gap-3 ${className}`}
		>
			<Col xs='auto' className='d-flex justify-content-center'>
				<FontAwesomeIcon size='lg' icon={faFaceFrown} />
			</Col>
			{serverError && <Col xs='auto'>{t('search.error.general')}</Col>}
			{noResults && (
				<Col xs='auto' className='text-center'>
					<Trans
						i18nKey='search.noResults'
						values={{ term: `"${searchTerm as string}"` }}
					/>
				</Col>
			)}
			{errorResponse && (
				<Col xs='auto' className='text-center'>
					<Heading
						variant='h3'
						className='fs-3 fw-bold mb-2'
						id='error-heading'
					>
						{errorResponse}
					</Heading>
					<div className='fs-5 fw-bold'>{getErrorMessage()}</div>
					<div className='mt-4'>{t('search.error.feedback')}</div>
					<Link to={`mailto:${externalUrls.reportErrorEmail}`}>
						{externalUrls.reportErrorEmail}
					</Link>
				</Col>
			)}
		</Row>
	)
}

export default ErrorMessage
