import { Row } from 'react-bootstrap'
import { styled } from 'styled-components'
import { over } from 'utils'

export const Wrapper = styled(Row)`
	justify-content: center;
	margin-bottom: 5rem;
	.big-link {
		cursor: pointer;
		border-radius: 5px;
		width: 100%;
		padding: 2rem 1.3rem;
		${over('sm')} {
			padding: 2.3rem 2rem;
		}

		svg {
			color: ${(props) => props.theme.colours.primary.blue};
		}
	}
	.snomed {
		background-color: #f2f7fe;
		padding: 2rem;
		border-radius: 3px;
	}

	.kis {
		background-color: #f7eaff;
	}

	.link-section {
		padding: 2rem 1.3rem;
		${over('sm')} {
			padding: 0;
		}
	}

	.clinical {
		background-color: #e5f8fb;
	}
	.lab {
		background-color: #e6fdf2;
	}
	.link-heading {
		font-weight: 500;
		&:hover {
			text-decoration: underline;
		}
	}
	.text-gray {
		color: ${(props) => props.theme.colours.primary.greyShade};
	}

	.text-blue {
		color: ${(props) => props.theme.colours.primary.blue};
	}
`
