import type { FilterType, FilterValue } from '@elastic/search-ui'
import {
	connector,
	resultFields,
} from 'components/search/buckets/ElasticSearch/config/settings'
import searchFields from '../../SearchWithFilter/config/settings/searchFields'
import { codesystems } from 'utils'

export const generalConfig = {
	apiConnector: connector,
	hasA11yNotifications: false,
	alwaysSearchOnInitialLoad: true,
	searchQuery: {
		search_fields: searchFields,
		filters: [
			{
				field: 'code_system',
				values: [
					codesystems.icd10,
					codesystems.phbu,
					codesystems.icpc2,
					codesystems.icpc2b,
					codesystems.nkpk,
					codesystems.nlk,
					codesystems.anatomisk,
					codesystems.tekstligeresultatverdier,
					codesystems.undersokelsesmetode,
					codesystems.norpat,
					codesystems.provemateriale,
				],
				type: 'any' as FilterType,
			},
			{
				field: 'active',
				values: ['true' as FilterValue],
				type: 'any' as FilterType,
			},
		],
		disjunctiveFacets: ['code_system', 'active', 'status_text', 'is_apat'],
		facets: {
			code_system: {
				type: 'value',
			},
			active: {
				type: 'value',
			},
			status_text: {
				type: 'value',
			},
			is_apat: {
				type: 'value',
			},
		},
		result_fields: resultFields,
	},
}
