const searchFields = {
	name_norwegian: {
		weight: 3,
	},
	code: {
		weight: 2,
	},
	compact_code: {
		weight: 1,
	},
	code_system: {
		weight: 1,
	},
	code_values: {
		weight: 1,
	},
	inclusion: {
		weight: 1,
	},
	notes: {
		weight: 1,
	},
	chapter_code: {
		weight: 1,
	},
}

export default searchFields
