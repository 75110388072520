const getToastColour = (status: string) => {
	switch (status) {
		case 'successAddItem':
		case 'sucessDownloadAll':
			return 'green'
		case 'removed':
		case 'errorAlreadyExists':
		case 'countError':
		case 'removeAll':
			return 'red'
		default:
			return ''
	}
}

export default getToastColour
