interface CodeSystems {
	[key: string]: string
}

const codesystems: CodeSystems = {
	icd10: 'ICD-10',
	icpc2: 'ICPC-2',
	icpc2b: 'ICPC-2B',
	nkpk: 'NKPK',
	phbu: 'PHBU',
	norpat: 'NORPAT',
	apat: 'APAT',
	nlk: 'NLK',
	adm: 'Kodeverk i standarder',
	anatomisk: 'Anatomisk lokalisasjon',
	provemateriale: 'Prøvemateriale',
	tekstligeresultatverdier: 'Tekstlige resultatverdier',
	undersokelsesmetode: 'Undersøkelsesmetode',
	snomedct: 'SNOMED CT',
	noSystem: 'default',
}

export default codesystems
